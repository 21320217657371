import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import { useState } from 'react';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { useApplicationContext } from '../../Context/applicationContext';
import KenStepperFooter from '../KenApplicationForm/Components/KenStepperFooter';
import ThankYouPage from '../KenApplicationForm/Components/ThankYouPage';
import KenSummaryStep from '../KenSummaryPage';
import { useNavigate } from 'react-router-dom';

import FormStep from './Form';

// Any special cases like payments,summary based on the stepType will be handled
// NOTE: These special steps should be coupled with any other steps

const useStyles = makeStyles((theme) => ({
  thankYouContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '80%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  title: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '20px',
      'line-height': '23px',
      color: '#000000',
    },
  },
  subTitle: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      'text-align': 'center',
      color: '#505F79',
    },
  },
  link: {
    color: '#0000EE',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function Stepper({ step }) {
  const { applicationState, applicationDispatch } = useApplicationContext();
  const formRef = useRef();
  console.log('Stepper');

  // based on stepType return components

  const getStepContent = () => {
    console.log('Stepper : getStepContent');

    switch (step?.stepType) {
      case 'payment':
        return <PaymentStep />;

      case 'summary':
        return (
          <SummaryStep
            applicationState={applicationState}
            applicationDispatch={applicationDispatch}
          />
        );

      default:
        return <FormStep step={step} ref={formRef} />;
    }
  };

  return (
    <>
      {/* render step by id */}
      {/* <KenStepperHeader activeStep={step} /> */}

      <Box m={2} alignItems="center" justifyContent="center">
        {getStepContent()}
      </Box>
    </>
  );
}

const PaymentStep = () => {
  return <>Payment Step</>;
};

const SummaryStep = (props) => {
  const { applicationState, applicationDispatch } = props;
  const classes = useStyles();
  const [showThankYou, setShowThankYou] = useState(false);
  const navigate = useNavigate();

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };
  const handleNext = () => {
    setShowThankYou(true);
    applicationDispatch({ type: 'showHeader' });
  };
  return (
    <>
      {/* Summary */}
      {!showThankYou ? (
        <>
          <Box sx={{ width: '80vw', margin: '0 auto' }}>
            <KenSummaryStep
              formJson={applicationState.steps}
              data={applicationState.applicationData}
              applicationState={applicationState}
            />
          </Box>
          <KenStepperFooter
            totalSteps={applicationState.totalSteps}
            activeStep={applicationState.activeStep}
            onPrev={handlePrev}
            onNext={handleNext}
          />
        </>
      ) : (
        <Box
          margin="auto"
          height="90%"
          p={2}
          display="flex"
          justifyContent="center"
          alignContent="center"
          flexDirection="column"
        >
          <ThankYouPage
            wrapperClassName={classes.thankYouContainer}
            titleClass={classes.title}
            subTitleClass={classes.subTitle}
            title="Thank you for your Application"
            description={
              <Typography>
                You have successfully Applied for
                {` ${applicationState.applicationData.application.campusName},
                    ${applicationState.applicationData.application.specializationName},
                    ${applicationState.applicationData.application.departmentName},
                    ${applicationState.applicationData.application.programName}`}
                <div style={{ fontSize: 14 }}>
                  Click here to redirect to{' '}
                  <span
                    className={classes.link}
                    onClick={() => {
                      applicationDispatch({ type: 'showHeader' });
                      navigate('/home');
                    }}
                  >
                    Homepage
                  </span>
                </div>
              </Typography>
            }
          />
        </Box>
      )}
    </>
  );
};
