import {
  Box,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import DiscountCard from './Components/DiscountCard';
import PaymentSummary from './Components/PaymentSumary';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';
import { useAppContext } from '../../../../Context/appContext';
import { AuthContext } from '../../../../Context/authContext';
import { postInitatePayment } from '../../../../Utils/apiServices';
import KenLoader from '../../../../Components/KenLoader';

const useStyles = makeStyles((theme) => ({
  nav: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  typoCourseDetails: {
    color: theme.palette.KenColors.grey3,
  },
}));

export default function FeeDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentMode, setPaymentMode] = useState('Full Payment');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [partialAmount, setPartialAmount] = useState(0);
  const [paymentModeTitle, setPaymentModeTitle] = useState();
  const [feeTitle, setFeeTitle] = useState('Aptitude Test Fee');
  const [basicFee, setBasicFee] = useState('');
  const [discountTitle, setDiscountTitle] = useState('Coupon Discount');
  const [errorInAmount, setErrorInAmount] = useState(false);
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const {
    state: { user },
  } = useContext(AuthContext);
  console.log(location.state);

  useEffect(() => {
    setBasicFee(
      location?.state?.dueFee?.remainingAmount
        ? location?.state?.dueFee?.remainingAmount
        : location?.state?.dueFee?.amount
    );
  }, [location]);

  const handleChangePaymentMode = (val) => {
    setPaymentMode(val);
  };

  const getDiscountAmount = (val) => {
    setDiscountAmount(val);
  };

  const getPartialAmount = (val) => {
    setPartialAmount(val || 0);
  };
  const getErrorInAmount = (val) => {
    setErrorInAmount(val);
  };

  useEffect(() => {
    if (paymentMode === 'Partial Payment') {
      setPaymentModeTitle('Pay Partial Amount');
    } else {
      setPaymentModeTitle('Pay Full Amount');
    }
  }, [paymentMode]);

  const goToDashboard = () => {
    navigate(-1);
  };

  const handlePay = () => {
    setLoader(true);
    const name = user?.applicantName?.split(' ');
    const amount = paymentMode === 'Partial Payment' ? partialAmount : basicFee;
    const isPartialPayment = paymentMode === 'Partial Payment' ? true : false;
    const RemainingAmount =
      paymentMode === 'Partial Payment'
        ? Number(basicFee) - Number(partialAmount)
        : 0;
    const payload = {
      applicationId: location?.state?.applicationId,
      studentId: user?.applicantId,
      firstName: name[0],
      lastName: name[1],
      dob: '01/15/2022',
      mobile: '',
      email: user?.applicantEmail,
      amount: Number(amount),
      paisa: '00',
      currencyCode: 'INR',
      callBackUrl: 'https://dev-admissionportal.ken42.com/thankYou',
      description: '',
      notes: [
        {
          isPartialPayment: isPartialPayment,
          fee: location?.state?.dueFee?.name,
          amount: Number(amount),
          Contact__c: user?.applicantId,
          Fee_Type__c: location?.state?.dueFee?.name,
          K42_Unplanned_Payment__c:
            location?.state?.dueFee?.unplannedPaymentId || '',
          Late_Fees__c: 0,
          Amount__c: Number(amount),
          Remaining_Amount: RemainingAmount,
          Total_Amount: basicFee,
          dueDate: location?.state?.dueFee?.dueDate,
        },
      ],
    };
    postInitatePayment(payload)
      .then((res) => {
        if (res?.data) {
          setLoader(false);
          window.open(res?.data?.url);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  return (
    <>
      {loader && <KenLoader />}
      <Grid
        container
        spacing={2}
        direction="column"
        padding={isMobileScreen ? theme.spacing(2) : undefined}
      >
        <Grid item>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            className={classes.nav}
            onClick={() => goToDashboard()}
          >
            <ArrowBackIcon style={{ height: '24px', width: '24px' }} />
            <Typography variant="subtitle1" sx={{ ml: 2, fontSize: 16 }}>
              Back
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mr={isMobileScreen ? 0 : 7}
          >
            <Grid container spacing={isMobileScreen ? 2 : 3}>
              <Grid item xs={12} sm={12} md={8}>
                <DiscountCard
                  handleChangePaymentMode={handleChangePaymentMode}
                  getDiscountAmount={getDiscountAmount}
                  getPartialAmount={getPartialAmount}
                  basicFee={basicFee}
                  getErrorInAmount={getErrorInAmount}
                  {...location?.state}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <PaymentSummary
                  paymentModeTitle={paymentModeTitle}
                  feeTitle={feeTitle}
                  partialAmount={partialAmount}
                  discountTitle={discountTitle}
                  discountAmount={discountAmount}
                  basicFee={basicFee}
                  errorInAmount={errorInAmount}
                  paymentMode={paymentMode}
                  handlePay={handlePay}
                  {...location?.state}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
