import * as React from 'react';
import { Box, Typography } from '@mui/material';
import KenIcon from '../../../KenIcon';
import DoneIcon from '../../../../Assets/Icons/tickInCircle.png';

export default function ThankYouPage(props) {
  const {
    icon,
    title = 'Fee Payment',
    description = "Your Fee's has been successfully paid.",
    wrapperClassName,
    titleClass,
    subTitleClass,
  } = props;
  return (
    <Box
      textAlign="center"
      margin="0 auto"
      width="50%"
      marginTop="30vh"
      className={wrapperClassName}
    >
      <Box>
        <KenIcon iconType="img" icon={DoneIcon}></KenIcon>
      </Box>

      <Box mt={3} mb={5}>
        <Typography variant="h4" fontWeight={500} className={titleClass}>
          {title}
        </Typography>
      </Box>

      <Box>
        <Typography
          variant="subtitle3"
          fontWeight={400}
          className={subTitleClass}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
}
