import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  CardContent,
  Grid,
  StepConnector,
  stepConnectorClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import DoneIcon from '@mui/icons-material/Done';
import styled from '@emotion/styled';
import KenButton from '../../../../../../Components/KenButton';
import { useNavigate } from 'react-router-dom';
import { TABLET_BREAKPOINT } from '../../../../../../Constants/constant';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  typoTitle: {
    fontWeight: 500,
    color: theme.palette.KenColors.primary,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      '&.Pending': {
        color: theme.palette.KenColors.grey4,
      },
    },
  },
  typoPaid: {
    color: theme.palette.KenColors.kenWhite,
    background: theme.palette.KenColors.statusGreen,
    width: '50%',
    margin: '0 auto',
    borderRadius: 4,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: 0,
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      height: '18px',
    },
    '&.Paid': {
      background: '#4BB99A',
      width: 'fit-content',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '&.Partial': {
      background: '#F6C445',
      width: 'fit-content',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  typoPending: {
    color: theme.palette.KenColors.kenWhite,
    background: theme.palette.KenColors.statusRed,
    width: '50%',
    margin: '0 auto',
    borderRadius: 4,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: 0,
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      height: '18px',
    },
    '&.Paid': {
      background: '#4BB99A',
      width: 'fit-content',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '&.Partial': {
      background: '#F6C445',
      width: 'fit-content',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  typoSubHeadingPaid: {
    color: theme.palette.KenColors.grey3,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontWeight: 400,
    },
  },
  typoSubHeadingPending: {
    color: theme.palette.KenColors.grey3,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontWeight: 400,
    },
  },
  typoSubHeadingPaidLabel: {
    color: theme.palette.KenColors.grey3,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-weight': '600',
      'font-size': '14px',
      'line-height': '150%',
      'text-transform': 'capitalize',
      color: theme.palette.KenColors.dark,
    },
  },
  amount: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-weight': '500',
      'font-size': '14px',
      'line-height': '100%',
      'text-align': 'right',
      'text-transform': 'capitalize',
      color: theme.palette.KenColors.dark,
    },
  },
  paidOnDate: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '14px',
      'line-height': '150%',
      'text-transform': 'capitalize',
      color: theme.palette.KenColors.grey2,
    },
  },
  download: {
    fontWeight: 500,
    color: theme.palette.KenColors.vibrantBlue,
    textDecorationLine: 'underline',
    marginRight: 16,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: 0,
    },
  },
  labelIcon: {
    background: theme.palette.KenColors.grey5,
  },
  completedIcon: {
    color: theme.palette.KenColors.statusGreen,
  },
  completedIconContainer: {
    background: theme.palette.KenColors.kenWhite,
    border: `2px solid ${theme.palette.KenColors.statusGreen}`,
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'flex-start',
  },
  activeIconContainer: {
    background: theme.palette.KenColors.kenWhite,
    fontSize: 16,
    fontWeight: 500,
    border: `2px solid ${theme.palette.KenColors.primary}`,
  },
  upcomingIcon: {
    color: theme.palette.KenColors.gradeSectionHeaderLabel,
    fontSize: 16,
    fontWeight: 500,
  },
  upcomingIconContainer: {
    border: `2px solid ${theme.palette.KenColors.grey5}`,
    background: theme.palette.KenColors.kenWhite,
  },
  verticalUpcomingIcon: {
    fontSize: 16,
    fontWeight: 500,
    paddingLeft: 7,
  },
  connector: {
    position: 'relative',
  },
}));
const STATUS = {
  PAID: 'Paid',
  PARTIAL: 'Partial',
  PENDING: 'Pending',
};

export default function FeeStepper(props) {
  const { feeData } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePayNow = () => {
    navigate('/feeDetails', { state: feeData });
  };

  const Connector = styled(StepConnector)(({ theme }) => ({
    [stepConnectorClasses.root]: {
      position: 'relative',
      color: 'red',
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.KenColors.neutral100,
      borderRadius: 1,
      position: 'absolute',
      top: -50,
      borderLeftStyle: 'dashed',
      borderLeftWidth: 1,
      minHeight: 100,
    },
  }));

  const CustomIcon = (props) => {
    const { index, activeStep } = props;
    return (
      <Grid
        item
        justifyContent="center"
        alignItems="center"
        sx={{ height: 26, width: 26, borderRadius: '50%' }}
        className={clsx(classes.labelIcon, {
          [classes.completedIconContainer]: index < activeStep,
          [classes.activeIconContainer]: index === activeStep,
          [classes.upcomingIconContainer]: index > activeStep,
        })}
      >
        {index < activeStep ? (
          <Grid item>
            <DoneIcon className={classes.completedIcon} />
          </Grid>
        ) : (
          <Grid
            item
            className={clsx(classes.completedIcon, {
              [classes.upcomingIcon]: index > activeStep,
            })}
          ></Grid>
        )}
      </Grid>
    );
  };

  return (
    <Box>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<Connector className={classes.connector} />}
      >
        {feeData?.feeDetailsList?.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              icon={<CustomIcon index={index} activeStep={activeStep} />}
            >
              <CardContent
                sx={{
                  background: '#F7F8FB',
                  borderRadius: '8px',
                  padding: `${theme.spacing(2)} !important`,
                }}
              >
                <Box>
                  {isMobileScreen ? (
                    <>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        textAlign="center"
                      >
                        <Grid
                          item
                          container
                          m={`0px 0px ${theme.spacing(2)} 0px !important `}
                        >
                          <Grid item xs={6} textAlign="left">
                            <Typography
                              variant="body7"
                              className={`${classes.typoTitle} ${step.status}`}
                            >
                              Registration Fee
                            </Typography>
                          </Grid>
                          {step.status !== STATUS.PENDING && (
                            <Grid
                              item
                              xs={6}
                              container
                              justifyContent="flex-end"
                            >
                              <Typography
                                variant="subtitle1"
                                className={`${classes.typoPaid} ${step.status}`}
                              >
                                {step.status}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          item
                          container
                          m={`0px 0px ${
                            !!step.dueAmount ? 0 : theme.spacing(2)
                          } 0px !important `}
                        >
                          <Grid item xs={6} textAlign="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.typoSubHeadingPaid}
                            >
                              Total Amount
                            </Typography>
                          </Grid>
                          <Grid item xs={6} container justifyContent="flex-end">
                            <Typography
                              variant="body7"
                              className={classes.amount}
                            >
                              {step.totalAmount}
                            </Typography>
                          </Grid>
                        </Grid>
                        {!!step.dueAmount && (
                          <Grid
                            item
                            container
                            m={`0px 0px ${theme.spacing(2)} 0px !important `}
                          >
                            <Grid item xs={6} textAlign="left">
                              <Typography
                                variant="subtitle2"
                                className={classes.typoSubHeadingPaid}
                              >
                                Due Amount
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              container
                              justifyContent="flex-end"
                            >
                              <Typography
                                variant="body7"
                                className={classes.amount}
                              >
                                {step.dueAmount}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        <Grid
                          item
                          container
                          m={`0px 0px ${theme.spacing(2)} 0px !important `}
                        >
                          <Grid item xs={6} textAlign="left">
                            {step.status !== STATUS.PENDING && (
                              <>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.typoSubHeadingPaidLabel}
                                >
                                  {step.paidOn ? 'Paid on' : 'Due on'}
                                </Typography>
                                <Typography
                                  variant="body7"
                                  className={classes.paidOnDate}
                                >
                                  {step.paidOn ? step.paidOn : step.dueDate}
                                </Typography>
                              </>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            container
                            justifyContent="flex-end"
                            alignItems="flex-end"
                          >
                            {!!step.paidOn ? (
                              ''
                            ) : (
                              <KenButton
                                disabled={step.status === STATUS.PENDING}
                                variant="roundedButton"
                                onClick={() => {
                                  handlePayNow();
                                }}
                              >
                                Pay Now
                              </KenButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      p={2}
                      textAlign={'center'}
                      spacing={2}
                    >
                      <Grid item xs={12} md={2}>
                        <Typography
                          variant="body7"
                          className={classes.typoTitle}
                        >
                          {step?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        {step.paymentDate &&
                        Number(step.remainingAmount) === 0 ? (
                          <Typography
                            variant="subtitle1"
                            className={classes.typoPaid}
                          >
                            Paid
                          </Typography>
                        ) : (
                          <Typography
                            variant="subtitle1"
                            className={classes.typoPending}
                          >
                            Pending
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={2} alignSelf="left">
                        <Typography
                          variant="subtitle2"
                          className={classes.typoSubHeadingPaid}
                        >
                          {step.paymentDate ? 'Paid on' : 'Due on'}
                        </Typography>
                        <Typography variant="body7">
                          {step.paymentDate
                            ? moment(step?.paymentDate).format('YYYY-MM-DD')
                            : step?.dueDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography
                          variant="subtitle2"
                          className={classes.typoSubHeadingPaid}
                        >
                          Total Amount
                        </Typography>
                        <Typography variant="body7">{step?.amount}</Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        {step?.remainingAmount !== null &&
                          Number(step?.remainingAmount) !== 0 && (
                            <>
                              <Typography
                                variant="subtitle2"
                                className={classes.typoSubHeadingPaid}
                              >
                                Remaining Amount
                              </Typography>
                              <Typography variant="body7">
                                {step?.remainingAmount}
                              </Typography>
                            </>
                          )}
                      </Grid>
                      <Grid item xs={12} md={2} textAlign="right">
                        {step?.status === 'Paid' ? (
                          ''
                        ) : (
                          <KenButton
                            variant="roundedButton"
                            onClick={() => {
                              handlePayNow();
                            }}
                          >
                            Pay Now
                          </KenButton>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </CardContent>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
