import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import SideNavigation from '../../Components/SideNavigation';
import HomePage from '../HomePage';
import MenuAppBar from '../../Components/MenuAppBar';
import DocumentPage from '../DocumentPage';
import { AuthContext } from '../../Context/authContext';
import Login from '../Auth/Login';
import SignUp from '../Auth/SignUp';
import routes from '../../Constants/routes';
import KenPrivateRoute from '../../Components/KenPrivateRoute';
import LoginOTP from '../Auth/Login/LoginOTP';
import VerifyNumber from '../Auth/SignUp/VerifyNumber';
import VerifyEmail from '../Auth/SignUp/VerifyEmail';
import VerifiedOTP from '../Auth/SignUp/VerifiedOTP';
import Courses from '../Courses';
import ApplicationForm from '../ApplicationForm';
import MyApplications from '../MyApplications';
import FeePayment from '../FeePayment';
import KenLoader from '../../Components/KenLoader';
import { useAppContext } from '../../Context/appContext';
import { getAxiosInstance, getConfig } from '../../Utils/apiServices';
import { useTheme } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { useIdleTimer } from 'react-idle-timer';
import FeeStructure from '../FeePayment/Components/FeeStructure';
import FeeDetails from '../FeePayment/Components/FeeDetails';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import ApplicationFormNew from '../ApplicationForm/Application';
import OfferLetter from '../OfferLetter';
import OfferLetterDetailed from '../OfferLetter/Components/OfferLetterDetailed';
import ThankYouPage from '../../Components/KenApplicationForm/Components/ThankYouPage';

export default function MainLayout(props) {
  const [open, setOpen] = React.useState(true);
  const {
    dispatch: dispatchApp,
    state: { isAuthenticated },
  } = useContext(AuthContext);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const theme = useTheme();
  const screenSize = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const { state: { isLoading = false }, dispatch } = useAppContext();

  const [config, setConfig] = useState();
  const location = useLocation();
  const [swipeableDrawer, setSwipeableDrawer] = useState();
  const isForm = location.pathname === '/form' || location.pathname === '/application';

  const [idle, setIdle] = useState(false);
  const handleOnIdle = () => {
    setIdle(true);
  };
  const timeout = 1000 * 60 * 10;

  // const methods = useIdleTimer({
  //   timeout,
  //   onIdle: handleOnIdle,
  // });

  useEffect(() => {
    if (idle === true) {
      dispatchApp({
        type: 'LOGOUT',
        payload: {
          user: {},
          token: null,
        },
      });
    }
  }, [idle]);

  React.useEffect(() => {
    getConfig()
      .then((res) => {
        dispatch({ type: 'updateConfig', value: res[0]?.config });
        getAxiosInstance(res);
        setConfig(res[0]?.config);
      })
      .catch((err) => {
        console.log('error in config', err);
      });
  }, []);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div style={!isForm ? { height: '100%' } : {}}>
        <SnackbarProvider autoHideDuration={5000}>
          <Box sx={{ display: 'flex', height: '100%' }}>
            <CssBaseline />
            {!config ? (
              <KenLoader />
            ) : (
              <>
                {isLoading && <KenLoader />}
                {isAuthenticated && !isForm && (
                  <>
                    <SideNavigation
                      open={open}
                      handleDrawerClose={handleDrawerClose}
                      handleDrawerOpen={handleDrawerOpen}
                      setSwipeableDrawer={setSwipeableDrawer}
                      swipeableDrawer={swipeableDrawer}
                      setOpen={setOpen}
                    />
                    <MenuAppBar
                      open={open}
                      handleDrawerOpen={handleDrawerOpen}
                      setSwipeableDrawer={setSwipeableDrawer}
                    />
                  </>
                )}
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    margin: isMobileScreen
                      ? '0px'
                      : !isAuthenticated || isForm
                      ? '0px'
                      : screenSize
                      ? '80px - 28px'
                      : '80px 16px',
                    height: !isAuthenticated ? '100%' : 'calc(100% - 160px)',
                    width: isMobileScreen ? '100%' : undefined,
                    paddingTop: isMobileScreen ? '65px' : undefined,
                    //  Note: added following so for main screen the gird is splitted on 12 grid after subtracting side navbar
                    maxWidth:
                      !isMobileScreen && isAuthenticated && !isForm
                        ? open
                          ? 'calc(100% - 260px)'
                          : 'calc(100% - 145px)'
                        : undefined,
                  }}
                >
                  <Routes>
                    <Route
                      path={'/form'}
                      element={
                        isAuthenticated ? (
                          <ApplicationForm />
                        ) : (
                          <Navigate to="/login" />
                        )
                      }
                    />
                    <Route
                      path={'/application'}
                      element={
                        isAuthenticated ? (
                          <ApplicationFormNew />
                        ) : (
                          <Navigate to="/login" />
                        )
                      }
                    />
                    <Route path={'/'} element={<Navigate to="/home" />} />
                    <Route
                      path={routes.login}
                      element={
                        isAuthenticated ? <Navigate to="/home" /> : <Login />
                      }
                    />
                    <Route
                      path={routes.signup}
                      element={
                        isAuthenticated ? <Navigate to="/home" /> : <SignUp />
                      }
                    />
                    <Route
                      path={routes.loginOTP}
                      element={
                        isAuthenticated ? <Navigate to="/home" /> : <LoginOTP />
                      }
                    />
                    <Route
                      exact
                      path={`/feeStructure`}
                      redirectTo="/home"
                      element={
                        <KenPrivateRoute>
                          <FeeStructure />
                        </KenPrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/offerLetter`}
                      redirectTo="/home"
                      element={
                        <KenPrivateRoute>
                          <OfferLetter />
                        </KenPrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/offerLetterDetailed`}
                      redirectTo="/home"
                      element={
                        <KenPrivateRoute>
                          <OfferLetterDetailed />
                        </KenPrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/feeDetails`}
                      redirectTo="/home"
                      element={
                        <KenPrivateRoute>
                          <FeeDetails />
                        </KenPrivateRoute>
                      }
                    />
                    <Route
                      path={routes.verifyNumber}
                      element={
                        isAuthenticated ? (
                          <Navigate to="/home" />
                        ) : (
                          <VerifyNumber />
                        )
                      }
                    />
                    <Route
                      path={routes.verifyEmail}
                      element={
                        isAuthenticated ? (
                          <Navigate to="/home" />
                        ) : (
                          <VerifyEmail />
                        )
                      }
                    />
                    <Route
                      path={routes.verifiedOTP}
                      element={
                        isAuthenticated ? (
                          <Navigate to="/home" />
                        ) : (
                          <VerifiedOTP />
                        )
                      }
                    />
                    <Route
                      path={routes.newApplication}
                      element={
                        isAuthenticated ? (
                          <Navigate to="/home" />
                        ) : (
                          <ApplicationForm />
                        )
                      }
                    />
                    <Route
                      exact
                      path={`/home`}
                      redirectTo="/login"
                      element={
                        <KenPrivateRoute>
                          <HomePage />
                        </KenPrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/document`}
                      redirectTo="/login"
                      element={
                        <KenPrivateRoute>
                          <DocumentPage />
                        </KenPrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/thankYou`}
                      redirectTo="/home"
                      element={
                        <KenPrivateRoute>
                          <ThankYouPage />
                        </KenPrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/feePayments`}
                      redirectTo="/home"
                      element={
                        <KenPrivateRoute>
                          <FeePayment />
                        </KenPrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`*`}
                      element={<Navigate to={routes.login} />}
                    />
                    <Route
                      exact
                      redirectTo={routes.login}
                      path={`/courses`}
                      element={
                        <KenPrivateRoute>
                          <Courses />{' '}
                        </KenPrivateRoute>
                      }
                    />
                    <Route
                      exact
                      redirectTo={routes.login}
                      path={`/${routes.myApplications}`}
                      element={
                        <KenPrivateRoute>
                          <MyApplications />
                        </KenPrivateRoute>
                      }
                    />
                  </Routes>
                </Box>
              </>
            )}
          </Box>
        </SnackbarProvider>
      </div>
    </>
  );
}
