import { Button } from "@mui/material";
import React from "react";
import AttachFileSharpIcon from "@mui/icons-material/AttachFileSharp";

export default function KenUploadButton(props) {
	const { open } = props;
	return (
		// <div style={{ border: "1px dashed #092682", borderRadius: 6 }}>
		<Button
			variant='secondary'
			onClick={open}
			startIcon={<AttachFileSharpIcon style={{ color: "#092682" }} />}
			style={{
				color: "#092682",
				border: "1px dashed #092682",
				borderRadius: 6,
				width: "100%",
			}}
		>
			Choose or Drag file
		</Button>
		// </div>
	);
}
