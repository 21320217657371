import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Popover,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { makeStyles } from "@mui/styles";
import Logo from "../../../../Assets/SvgImages/spjainDetailed.svg";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../Context/authContext";
import { TABLET_BREAKPOINT } from "../../../../Constants/constant";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: "100%",
  backgroundColor: theme.palette.KenColors.neutral900,
  paddingTop: 8,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.KenColors.neutral900,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  icon: {
    color: theme.palette.KenColors.kenBlack,
  },
  logoWrapper: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'padding': '5px',
      'width': '100%',
      alignItems: 'center',
      marginLeft: '0px',
    }
  },
  iconWrapper: {
    'width': '40px',
    'height': '40px',
    'display': 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    background: "#F4F5F7",
    borderRadius: "50%",
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'padding': '0px',
      'height': '24px',
      'width': '24px',
      'display': 'flex',
      'justify-content': 'center',
      'align-items': 'center',

      '& > svg': {
        'height': '16px',
      }
    },
  },
  name: {
    color: theme.palette.KenColors.kenWhite,
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '16px',
    'line-height': '100%',

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-size': '12px',
    }
  },
  avatar: {
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'height': '24px',
      'width': '24px',
      'font-size': '12px',
      marginRight: '10px'
    }
  },
  logo: {
    height: 54,
  },
  options: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      '& >.MuiPaper-root': {
        'left': 'unset !important',
        right: theme.spacing(1),
        'width': '68px !important',
        'overflow': 'hidden',
        'height': '48px !important',
      }
    }
  }
}));

export default function FormHeader(props) {
  const classes = useStyles();
  const {
    state: { user },
    dispatch,
  } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(
    TABLET_BREAKPOINT
  ));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  return (
    <AppBar position='fixed' elevation={0}>
      <Toolbar classes={{ root: classes.root }} variant='regular'>
        <Grid
          container
          direction='row'
          spacing={2}
          justifyContent='space-between'
          sx={{ paddingLeft: "106px", paddingRight: "104px", marginLeft: 0, width: '100%' }}
          className={classes.logoWrapper}
        >
          <Grid item paddingLeft={isMobileScreen ? '0px !important' : undefined} sx={{ paddingLeft: '0px !important', paddingTop: `${theme.spacing(1.5)} !important` }}>
            <img src={Logo} className={classes.logo} alt="" />
          </Grid>
          <Grid item paddingTop={isMobileScreen ? '0px !important' : `${theme.spacing(2.5)} !important`}>
            <Grid
              container
              alignItems='center'
              justifyContent='end'
              spacing={3}
              sx={{ marginTop: 0 }}
            >
              <Grid item sx={{ float: "right", paddingTop: `0px !important` }}>
                <Box className={classes.iconWrapper}>
                  <NotificationsNoneIcon className={classes.icon} />
                </Box>
              </Grid>
              <Grid
                item
                sx={{ float: "right", paddingTop: `0px !important` }}
                paddingLeft={isMobileScreen ? '5px !important' : undefined}
              >
                <Typography className={classes.name}>
                  {user?.applicantName?.split(" ")[0]}
                </Typography>
              </Grid>
              <Grid
                item
                onClick={handleClick}
                paddingLeft={isMobileScreen ? '5px !important' : '10px !important'}
                sx={{ paddingTop: `0px !important` }}
              >
                <Avatar className={classes.avatar}>
                  {user?.applicantName[0]}
                </Avatar>
              </Grid>
            </Grid>
          </Grid>
          <Popover
            className={classes.options}
            id={id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography
              sx={{ p: 2 }}
              onClick={() => {
                // TODO: remove once signup is in place
                dispatch({
                  type: "LOGOUT",
                  payload: {
                    user: {},
                    token: null,
                  },
                });
              }}
              className={classes.avatar}
            >
              Logout
            </Typography>
          </Popover>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
