import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { orange } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import KenButton from '../../../../../../../Components/KenButton';
import { TABLET_BREAKPOINT } from '../../../../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFF',
  },
  title: {
    color: '#061938',
  },
  grey: {
    color: '#505F79',
  },
  lightGrey: {
    color: '#7A869A',
  },
  green: {
    color: '#158467',
  },
  orange: {
    color: '#E75547',
  },
}));

export default function CardContent(props) {
  const {
    paymentModeTitle,
    paymentMode,
    feeTitle,
    discountTitle,
    discountAmount,
    partialAmount,
    errorInAmount,
    basicFee,
    handlePay,
  } = props;
  const classes = useStyles();
  const [amountToPay, setAmountToPay] = useState(basicFee);
  const [remainingAmount, setRemainingAmount] = useState(basicFee);
  const [amountAfterDiscount, setAmountAfterDiscount] = useState(basicFee);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  useEffect(() => {
    console.log('discountAmount', discountAmount, props);
    getFinalAmount();
  }, [props, amountToPay]);

  useEffect(() => {
    setAmountAfterDiscount(basicFee - discountAmount);
  }, [discountAmount, basicFee]);

  useEffect(() => {
    getRemainingAmount();
  }, [props, amountToPay]);

  const getFinalAmount = () => {
    if (paymentMode === 'Full Payment') {
      setAmountToPay(basicFee - discountAmount);
    } else {
      setAmountToPay(partialAmount);
    }
  };
  const getRemainingAmount = () => {
    if (paymentMode === 'Partial Payment') {
      if (basicFee - discountAmount - partialAmount < 0) {
        setRemainingAmount(0);
        setAmountToPay(basicFee - discountAmount);
      } else {
        setRemainingAmount(basicFee - discountAmount - partialAmount);
      }
    }
  };
  return (
    <Box p={3} pt={isMobileScreen ? 2 : 4} className={classes.container}>
      <Box>
        <Typography
          fontWeight={600}
          textAlign="left"
          mb={isMobileScreen ? 1 : 2}
          className={classes.title}
        >
          Payment Summary
        </Typography>
        <Typography textAlign="left" className={classes.grey}>
          {paymentModeTitle}
        </Typography>
      </Box>

      <Box pt={6}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6} textAlign="left" className={classes.grey}>
            {feeTitle}
          </Grid>
          <Grid item xs={2} className={classes.grey}>
            (+)
          </Grid>
          <Grid item xs={4} textAlign="right" className={classes.grey}>
            ₹ {(Math.round(basicFee * 100) / 100).toFixed(2)}
          </Grid>

          <Grid item xs={6} textAlign="left" className={classes.grey}>
            {discountTitle}
          </Grid>
          <Grid item xs={2} className={classes.grey}>
            (-)
          </Grid>
          <Grid item xs={4} textAlign="right" className={classes.green}>
            ₹ {(Math.round(discountAmount * 100) / 100).toFixed(2)}
          </Grid>

          <Grid item xs={6} textAlign="left" className={classes.grey}>
            Net Fee
          </Grid>
          <Grid item xs={6} textAlign="right" className={classes.grey}>
            ₹ {(Math.round(amountAfterDiscount * 100) / 100).toFixed(2)}
          </Grid>

          <Grid item xs={12}>
            <Box pt={2}>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography padding="16px 0px" className={classes.grey}>
              To Pay
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography padding="16px 0px" fontSize="24px" fontWeight={600}>
              ₹ {amountToPay}
              {/* ₹ { getFinalAmount()} */}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box pt={2}>
              <KenButton
                // label={`Pay ₹ ${getFinalAmount()}`}
                onClick={handlePay}
                label={`Pay ₹ ${amountToPay}`}
                variant="roundedButton"
                style={{ width: '100%' }}
                disabled={errorInAmount}
              />
            </Box>
          </Grid>
          {paymentMode === 'Partial Payment' && partialAmount != basicFee && (
            <>
              <Grid item xs={12} md={6} textAlign="left">
                <Typography className={classes.lightGrey} pt={1} pb={3}>
                  Balance remaining
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} textAlign="right">
                <Typography className={classes.orange} pt={1} pb={3}>{`₹ ${(
                  Math.round(remainingAmount * 100) / 100
                ).toFixed(2)}`}</Typography>{' '}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
}
