import { Grid } from '@mui/material';
import React from 'react';
import KenPDFViewer from '../../../../Components/KenPdfViewer';

export default function OfferLetterDetailed(props) {
  return (
    <Grid container direction="row" spacing={5}>
      <Grid item md={3}>
        <KenPDFViewer toolbarFlag={false} />
      </Grid>
      <Grid item md={9}>
        <KenPDFViewer />
      </Grid>
    </Grid>
  );
}
