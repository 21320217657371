import {
  Box,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import FeeTextField from './feeTextField';
import FeeRadioGroup from './feeRadioGroup';
import PaymentMode from './paymentMode';
import { TABLET_BREAKPOINT } from '../../../../../../../Constants/constant';
import { postFeeCoupon } from '../../../../../../../Utils/apiServices';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFF',
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px',

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: 0,
      'padding-top': theme.spacing(2),
    },
  },
}));

export default function CardContent(props) {
  const {
    handleChangePaymentMode,
    getErrorInAmount,
    getDiscountAmount,
    getPartialAmount,
    basicFee,
  } = props;
  const classes = useStyles();
  const [couponValue, setCouponValue] = useState();
  const [couponValid, setCouponValid] = useState();
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [paymentMode, setPaymentMode] = useState('Full Payment');
  const [discountAmount, setDiscountAmount] = useState();
  const [partialAmount, setPartialAmount] = useState();
  const [partialAmountFieldError, setPartialAmountFieldError] = useState();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const isCouponValid = (coupon) => {
    //TODO: API call
    postFeeCoupon({
      applicantId: '0031y00000K0VkvAAF',
      programId: 'a0K1y000002JQfaEAG',
      couponCode: 'fhgjhgkjhk',
    }).then((res) => {
      console.log(res);
    });
    return coupon === '123456';
  };
  const handleCouponCodeChange = (e) => {
    setCouponValue(e.target.value);
    setInvalidCoupon(false);
  };

  const handleCouponCodeApply = () => {
    postFeeCoupon({
      applicantId: '0031y00000K0VkvAAF',
      programId: 'a0K1y000002JQfaEAG',
      couponCode: 'fhgjhgkjhk',
    }).then((res) => {
      if (res?.isValid === true && res?.success === true) {
        setCouponValid(true);
        setDiscountAmount(res?.couponAmount);
        setSuccess({ message: `-₹ ${res?.couponAmount} Coupon Applied` });
        setError();
        getDiscountAmount(res?.couponAmount);
      } else {
        setInvalidCoupon(true);
        setError('Applied coupon is invalid');
        getDiscountAmount(0);
      }
    });
  };
  const handleCouponCodeRemove = () => {
    setCouponValue('');
    setCouponValid(false);
    setSuccess();
    setError('');
    getDiscountAmount(0);
  };
  const handleOnChange = (e) => {
    handleChangePaymentMode(e.target.value);
    setPaymentMode(e.target.value);
  };
  const onPartialAmountChange = (e) => {
    setPartialAmount(e.target.value);
  };
  const validatePartialAmount = (val) => {
    if (val) {
      if (isNaN(val)) {
        setPartialAmountFieldError('Please enter valid number');
      } else {
        if (Number(val) > Number(basicFee)) {
          setPartialAmountFieldError(
            'You have entered an amount more than total fee'
          );
        } else {
          setPartialAmountFieldError();
        }
      }
    } else {
      if (paymentMode === 'Partial Payment') {
        setPartialAmountFieldError('Partial amount is required');
      } else {
        setPartialAmountFieldError();
      }
    }
  };

  useEffect(() => {
    if (partialAmount) {
      getPartialAmount(partialAmount);
    } else {
      getPartialAmount(0);
    }
    validatePartialAmount(partialAmount);
  }, [partialAmount]);

  useEffect(() => {
    if (partialAmountFieldError) {
      getPartialAmount(0);
      getErrorInAmount(true);
    } else {
      getPartialAmount(partialAmount);
      getErrorInAmount(false);
    }
  }, [partialAmountFieldError]);

  useEffect(() => {
    if (paymentMode === 'Full Payment') {
      getErrorInAmount(false);
    } else {
      if (partialAmountFieldError) {
        getPartialAmount(0);
        getErrorInAmount(true);
      } else {
        getPartialAmount(partialAmount);
        getErrorInAmount(false);
      }
    }
  }, [paymentMode]);

  return (
    <Box p={3} pt={6} className={classes.container}>
      {/* <Box pl={3}> */}
      <FeeTextField
        value={couponValue}
        handleCouponCodeChange={handleCouponCodeChange}
        handleCouponCodeApply={handleCouponCodeApply}
        handleCouponCodeRemove={handleCouponCodeRemove}
        success={success}
        couponValid={couponValid}
        invalidCoupon={invalidCoupon}
        error={error}
      />
      {/* </Box> */}
      {!isMobileScreen && (
        <Box pb={5} pt={3}>
          <Divider />
        </Box>
      )}

      <PaymentMode
        handleOnChange={handleOnChange}
        value={paymentMode}
        onPartialAmountChange={onPartialAmountChange}
        partialAmount={partialAmount}
        partialAmountFieldError={partialAmountFieldError}
      />
    </Box>
  );
}
