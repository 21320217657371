import React from 'react';
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  li: {
    textAlign: 'left',
    padding: theme.spacing(1),
    paddingLeft: '5px',
  },
}));

const Instructions = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  return (
    <>
      <Box p={2} width={isMobileScreen ? '100%' : '80%'} margin="0 auto">
        <Typography
          fontWeight={700}
          variant="h4"
          color="primary"
          pt={3}
          align="left"
          textTransform="uppercase"
        >
          Instructions
        </Typography>
        <Box py={isMobileScreen ? 1 : 3}>
          <Divider />
        </Box>

        <Box pt={0}>
          <Typography
            fontWeight={500}
            color="primary"
            align="left"
            variant="subtitle2"
            fontSize={'20px'}
          >
            Important Notes
          </Typography>
          <ol>
            <li className={classes.li}>
              All the fields are required unless marked optional.
            </li>
            <li className={classes.li}>
              This is a new kind of application process where you can select a
              section to complete.
            </li>
            <li className={classes.li}>
              Click on ‘Apply Now’ as shown against the respective
              Course/Department you want to apply.
            </li>
            <li className={classes.li}>
              Before filling up the form, candidates are advised to carefully go
              through instructions
            </li>
          </ol>
        </Box>

        {/* <Box>
					<Typography
						fontWeight={500}
						color='primary'
						pb={2}
						align='left'
						variant='subtitle2'
						fontSize={"20px"}
					>
						Mandatory Documents
					</Typography>
					<Typography align='left'>
						Please ensure that you possess the following documents for upload
						before continuing
					</Typography>
					<ul>
						<li className={classes.li}>Birth Certificate</li>
						<li className={classes.li}>Aadhaar Card</li>
						<li className={classes.li}>Passport</li>
						<li className={classes.li}>Provisional Certificate</li>
					</ul>
				</Box> */}
      </Box>
    </>
  );
};

export default Instructions;
