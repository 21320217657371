import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";

import DescriptionBox from "./Components/DescriptionBox";
import {
  getAllPrograms,
  getApplicationsByApplicantId,
} from "../../Utils/apiServices";
import KenLoader from "../../Components/KenLoader";
import { useTranslation } from "react-i18next";
import CoursesBox, { ExtraCourseCard } from "../HomePage/Components/CoursesBox";
import ApplicationProcess from "../HomePage/Components/ApplicationProcess";
import CourseCard from "../../Cards/CourseCard";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/authContext";
import ApplicationCard from "../../Cards/ApplicationCard";
import { FormContext } from "../../Context/formContext";
import { TABLET_BREAKPOINT } from "../../Constants/constant";

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      overflowX: 'auto',
      paddingBottom: '16px',
      width: '100%',
    }
  },
  heading: {
    textAlign: "left",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "100%",
    letterSpacing: "-0.02em",
    textTransform: "capitalize",
    color: "#061938",
  },
  subHeading: {
    textDecoration: "none",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "100%",
    letterSpacing: "-0.02em",
    textTransform: "capitalize",
    color: "#061938",
  },

  courseCount: {
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "100%",
    color: "#7A869A",
    textAlign: "left",
  },
  typoOtherCourse: {
    marginBottom: "60px",
    marginTop: "16px",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "100%",
  },
}));

export default function MyApplications() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [programs, setPrograms] = useState();
  const [loading, setLoading] = useState();
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();
  const [navigatePath, setNavigatePath] = useState();
  const [enrolledApplications, setEnrolledApplications] = useState();
  const [active, setActive] = useState();
  const {
    state: { user },
  } = useContext(AuthContext);
  const [applicationStatus, setApplicationStatus] = useState();
  const navigate = useNavigate();
  const { formState, formDispatch } = useContext(FormContext);

  const goToApplicationForm = (item) => {
    formDispatch({
      type: "new",
      payload: {
        programDetails: item,
        applicationId: item.recordId,
        formId: item.applicationForm,
      },
    });

    navigate({
      pathname: "/application",
      search: `?applicationId=${item.recordId}&formId=${item.applicationForm}&type=${item?.departmentName}`,
    });
  };

  useEffect(() => {
    if (user) {
      setLoading(true);
      getApplicationsByApplicantId(user?.applicantId)
        .then((res) => {
          if (res?.data) {
            const data = res?.data?.map((el) => {
              return {
                programName: el.programName,
                programId: el.programId,
                endDate: el.courseEndDate,
                startDate: el.courseStartDate,
                ...el,
              };
            });
            setEnrolledApplications(data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (enrolledApplications?.length > 0) {
      setNavigatePath("/courses");
      setTitle(t("headings:Apply_For_More_Courses"));
      setMessage(t("messages:Start_New_Applications_description"));
    } else {
      setTitle(t("headings:Start_New_Applications"));
      setMessage(t("messages:Start_New_Applications_description"));
    }
  }, [enrolledApplications]);

  // useEffect(() => {
  //   if (enrolledApplications?.length > 0) {
  //     setLoading(true);
  //     getAllPrograms()
  //       .then((res) => {
  //         console.log(res);
  //         if (res?.data) {
  //           setPrograms([...res?.data]);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setLoading(false);
  //       });
  //   }
  // }, [enrolledApplications]);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(
    TABLET_BREAKPOINT
  ));

  return (
    <Box sx={{ mt: 2, pb: 2 }} padding={isMobileScreen ? '0px 16px 16px 16px' : undefined}>
      {loading && <KenLoader />}
      <Grid container spacing={2}>
        {/*  <Grid md={12}>
          <DescriptionBox
            title={title}
            message={message}
            navigatePath={navigatePath}
          />
        </Grid> */}
        <Grid item md={12} className={classes.sliderContainer}>
          <ApplicationProcess applicationStatus={applicationStatus} />
        </Grid>
        {/* <Grid md={12}>
          <Box style={{ padding: "12px", borderRadius: "16px" }}>
            <CoursesBox programs={programs} setPrograms={setPrograms} />
          </Box>
        </Grid> */}
        <Grid item md={12}>
          <Divider variant="fullWidth" sx={{ marginBottom: "12px" }} />
        </Grid>
        <Grid md={12}>
          <Box style={{ padding: "12px", borderRadius: "16px" }}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Typography className={classes.heading}>
                  {enrolledApplications && enrolledApplications?.length > 0
                    ? `My Applications(${enrolledApplications?.length | 0})`
                    : enrolledApplications?.length === 0
                      ? `My Applications(${enrolledApplications?.length | 0})`
                      : "Our Courses()"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={12} width={isMobileScreen ? '100%' : undefined}>
          <Grid container spacing={3}>
            {enrolledApplications?.length > 0 ? (
              enrolledApplications.map((item, index) => {
                return (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={3} paddingTop={isMobileScreen ? theme.spacing(2) : undefined}>
                      <ApplicationCard
                        {...item}
                        status={30}
                        item={item}
                        active={active}
                        setActive={setActive}
                        setApplicationStatus={setApplicationStatus}
                        currentStep={item?.currentStep}
                        goToApplicationForm={goToApplicationForm}
                      />
                    </Grid>

                    {index === enrolledApplications.length - 1 && (
                      <Grid item xs={12} sm={6} md={4} lg={3} sx={{ maxWidth: isMobileScreen ? undefined : '290px !important' }}>
                        <ExtraCourseCard />
                      </Grid>
                    )}
                  </>
                );
              })
            ) : (
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ maxWidth: isMobileScreen ? undefined : '290px !important' }}>
                <ExtraCourseCard />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
