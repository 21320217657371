import React, { useContext } from 'react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import KenButton from '../../../KenButton';
import { FormContext } from '../../../../Context/formContext';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';
import BackButton from '../../../../Assets/Svg/back-button.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    background: '#FFFFFF',
    boxShadow:
      '10px 0px 20px rgba(0, 0, 0, 0.04), 2px 0px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    paddingRight: '223px',
    paddingLeft: '223px',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    minHeight: 76,
    [theme.breakpoints.down('xs')]: {
      margin: 12,
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '0px 16px',
      'min-height': '60px',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      position: 'fixed',
      bottom: '0px',
      width: '100%',
    },
  },
  previousBtn: {
    gap: '10px',
    width: '111px',
    'border-radius': '4px',
    border: '0px !important',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '18px !important',
    'line-height': '100% !important',
    display: 'flex',
    'text-align': 'center',
    color: '#092682 !important',
    backgroundColor: 'transparent !important',
  },
  saveBtn: {
    gap: '10px',
    'border-radius': '4px',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '18px !important',
    'line-height': '100% !important',
    display: 'flex',
    'text-align': 'center',
    color: '#092682 !important',
    backgroundColor: 'transparent !important',
    padding: '8px',
    width: '160px',
    height: '48px',
    border: '1px solid #092682',

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: 148,
    },
  },
  nextBtn: {
    display: 'flex',
    padding: '8px',
    gap: '8px',
    width: '140px',
    height: '48px',
    background: '#F6C445',
    'border-radius': '4px',
    'font-style': 'normal',
    'font-weight': '500 !important',
    'font-size': '18px !important',
    'line-height': '100% !important',
    'text-align': 'center',

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginRight: '0px',
      width: 80,
    },
  },
}));

export default function KenStepperFooter(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  const {
    onNext,
    onPrev,
    onSave,
    isSave = true,
    isPrev = true,
    isNext = true,
    activeStep,
    totalSteps,
  } = props;

  const {
    formState: {
      formData: { application },
    },
  } = useContext(FormContext);

  return (
    <div
      className={classes.root}
      style={{
        display:
          application?.applicationStatus === 'Application form Submitted'
            ? 'none'
            : '',
      }}
    >
      <Grid container justifyContent={'space-between'} direction="row">
        <Grid item>
          {isPrev && (
            <KenButton
              buttonClass={classes.previousBtn}
              variant="outlined"
              startIcon={
                isMobileScreen ? null : <img src={BackButton} alt="" />
              }
              onClick={onPrev}
              disabled={
                activeStep === 0 ||
                application?.applicationStatus === 'Application form Submitted'
              }
              style={{
                marginRight: 8,
                pointerEvents: 'auto',
                cursor:
                  activeStep === 0 ||
                  application?.applicationStatus ===
                    'Application form Submitted'
                    ? 'not-allowed'
                    : 'pointer',
              }}
            >
              {isMobileScreen ? 'Prev' : 'Previous'}
            </KenButton>
          )}
        </Grid>
        <Grid item>
          <>
            <Grid container justifyContent="flex-end">
              <Grid item>
                {isSave && (
                  <KenButton
                    buttonClass={classes.saveBtn}
                    variant="outlined"
                    style={{ marginRight: 8 }}
                    onClick={onSave}
                  >
                    Save
                  </KenButton>
                )}
              </Grid>
              <Grid item>
                {isNext && (
                  <KenButton
                    buttonClass={classes.nextBtn}
                    type="submit"
                    form={`form-step${activeStep}`}
                    variant="contained"
                    onClick={onNext}
                    disabled={
                      application?.applicationStatus ===
                      'Application form Submitted'
                    }
                    endIcon={
                      isMobileScreen ? null : <ArrowForwardOutlinedIcon />
                    }
                  >
                    {activeStep === totalSteps - 1 ? 'Submit' : 'Next'}
                  </KenButton>
                )}
              </Grid>
            </Grid>
          </>

          {/* <div style={{ marginLeft: 8 }}>
								{allStepsCompleted() ? (
									<React.Fragment>
										<Typography sx={{ mt: 2, mb: 1 }}>
											All steps completed
										</Typography>
									//	<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
							<Box sx={{ flex: "1 1 auto" }} />
							<Button onClick={handleReset}>Reset</Button>
						</Box>//
									</React.Fragment>
								) : (
									<React.Fragment>
										{activeStep !== steps.length &&
											(completed[activeStep] ? (
												<Typography
													variant='caption'
													sx={{ display: "inline-block" }}
												>
													Step {activeStep + 1} already completed
												</Typography>
											) : (
												<KenButton onClick={handleComplete} variant='contained'>
													{completedSteps() === totalSteps - 1
														? "Finish"
														: "Save and Proceed"}
												</KenButton>
											))}
									</React.Fragment>
								)}
							</div> */}
          {/* </React.Fragment> */}
        </Grid>
      </Grid>
    </div>
  );
}
