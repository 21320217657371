import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Divider, Grid, useTheme, useMediaQuery } from '@mui/material';
import KenButton from '../../Components/KenButton';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.card.backgroundColor,
    mixBlendMode: 'normal',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '12px',
    border: 'none',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      boxShadow: 'unset',
    },
  },
  color: {
    color: theme.palette.KenColors.neutral900,
  },
  red: {
    color: theme.palette.error.main,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  statusButton: {
    minWidth: '98px',
  },
  tagContainer: {
    background: theme.palette.KenColors.kenWhite,
    borderRadius: '70px',
    color: theme.palette.KenColors.grey2,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      height: '24px',
      border: `1px solid ${theme.palette.KenColors.neutral400}`,
      'border-radius': '70px',
      display: 'flex',
      alignItems: 'center',

      '& >h6': {
        'font-style': 'normal',
        'font-weight': '500',
        'font-size': '12px',
        'line-height': '100%',
        color: theme.palette.KenColors.neutral400,
      },
    },
  },
  amount: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '100%',
    'text-align': 'right',
    'text-transform': 'capitalize',
    color: theme.palette.KenColors.neutral900,
  },
  applicationNumber: {
    color: theme.palette.KenColors.grey2,
    fontWeight: 500,
  },
  due: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '14px',
      'line-height': '100%',
      'text-align': 'right',
      'text-transform': 'capitalize',
      color: theme.palette.KenColors.statusRed,
    },
  },
  btn: {
    width: '100%',
    maxWidth: theme.spacing(22.5),
  },
  mobilBtn: {
    'border-radius': '50% !important',
    height: '48px !important',
    width: '48px !important',
    padding: '0 !important',
    'min-width': '48px',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',

    '& > span:first-child': {
      margin: 'auto',
    },
  },
}));

export function MobilePaymentCard(props) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ p: theme.spacing(2) }}
    >
      <Grid
        item
        container
        justifyContent="space-between"
        paddingBottom={theme.spacing(2)}
      >
        <Grid
          item
          container
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="column"
          xs={6}
        >
          <Grid paddingBottom={theme.spacing(1.5)}>
            <Typography
              fontSize="20px"
              color="text.primary"
              fontWeight="500"
              textAlign="left"
            >
              {props.programName}
            </Typography>
          </Grid>
          {/* <Grid className={classes.tagContainer}>
            <Typography
              marginLeft="12px"
              marginRight="12px"
              variant="subtitle2"
              className={classes.subTitle}
            >
              Microbiology
            </Typography>
          </Grid> */}
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <KenButton
            buttonClass={classes.mobilBtn}
            variant="roundedButton"
            onClick={() => {
              props.handleViewDetails();
            }}
            startIcon={<ChevronRightIcon style={{ fontSize: '25px' }} />}
          ></KenButton>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body6" className={classes.applicationNumber}>
          {props.applicationName}
        </Typography>
      </Grid>
      <Divider sx={{ marginY: theme.spacing(2), width: '100%' }} />
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid>
          <Typography variant="body6" className={classes.applicationNumber}>
            {props?.dueFee?.name}
          </Typography>
        </Grid>
        <Grid>
          <Typography
            fontSize="20px"
            color="text.primary"
            fontWeight="500"
            className={classes.amount}
          >
            {props?.dueFee?.amount}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid>
          <Typography variant="body6" className={classes.due}>
            {props.dueFee?.dueDate}
          </Typography>
        </Grid>
        {/* <Grid>
          <Typography fontSize={14}></Typography>
        </Grid> */}
      </Grid>
    </Grid>
  );
}

export default function PaymentCard(props) {
  const classes = useStyles();
  const {
    programName,
    transactionStatus,
    fee,
    duration,
    instituteName,
    applicationId,
    dueDate,
    currencyCode,
  } = props;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const card = (
    <React.Fragment>
      <CardContent>
        <Grid container spacing={1} rowSpacing={0}>
          <Grid item xs={7} textAlign="left">
            <Typography color="text.primary" fontSize="20px" noWrap>
              {programName}
            </Typography>
          </Grid>
          <Grid item xs={5} textAlign="right">
            {/* <Typography color='text.primary' noWrap>
							be lent
						</Typography> */}
            <KenButton
              label={transactionStatus === 'due' ? 'Payment Due' : 'Paid'}
              variant={transactionStatus === 'due' ? 'error' : 'success'}
              className={classes.statusButton}
            />
          </Grid>
          <Grid item xs={12} textAlign="left" pt={0}>
            <Typography
              color="text.secondary"
              variant="subtitle2"
              fontWeight={400}
              mb={1}
              noWrap
            >
              {`${duration} | ${instituteName}`}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="left" mb={2}>
            <Typography color="text.primary" variant="subtitle2" noWrap>
              {applicationId}
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6} textAlign="left">
              <Typography
                color="text.secondary"
                variant="subtitle2"
                fontWeight={400}
              >
                Total Fee
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography color="text.primary" variant="subtitle2">
                {`  ${currencyCode} ${fee}`}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="left">
              {transactionStatus === 'due' ? (
                <Typography
                  color="text.secondary"
                  variant="subtitle2"
                  fontWeight={400}
                >
                  Payment Due:
                </Typography>
              ) : (
                <Typography
                  color="text.secondary"
                  variant="subtitle2"
                  fontWeight={400}
                >
                  Paid On:
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography
                color="text.primary"
                variant="subtitle2"
                className={
                  transactionStatus === 'due' ? classes.red : classes.primary
                }
              >
                {` ${dueDate}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{ justifyContent: 'center' }}>
        <KenButton
          label={transactionStatus === 'due' ? 'Pay Now' : 'View Receipt'}
        />
      </CardActions>
    </React.Fragment>
  );

  const cardChanged = (
    <React.Fragment>
      <CardContent>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 1 }}
        >
          <Grid item>
            <Typography
              fontSize="18px"
              color="text.primary"
              fontWeight="500"
              sx={{ overflow: 'hidden', textAlign: 'left' }}
              // maxWidth={theme.spacing(12)}
            >
              {programName}
            </Typography>
          </Grid>
          {/* <Grid item sx={{ padding: '8px' }} className={classes.tagContainer}>
            <Typography
              marginLeft="12px"
              marginRight="12px"
              variant="subtitle2"
            >
              Manipal University
            </Typography>
          </Grid> */}
          <Grid item>
            <Typography variant="body6" className={classes.applicationNumber}>
              {props.applicationName}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          sx={{ mt: 2 }}
        >
          <Grid container item direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="body6" className={classes.applicationNumber}>
                {props?.dueFee?.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize="20px" color="text.primary" fontWeight="500">
                {props?.dueFee?.remainingAmount || props?.dueFee?.amount}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography fontSize={14} className={classes.red}>
              {props.dueFee?.dueDate}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ padding: theme.spacing(2) }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <KenButton
              buttonClass={classes.btn}
              label={
                props?.dueFee?.status === 'Partial' ||
                props?.dueFee?.status === 'Due'
                  ? 'Pay Now'
                  : 'View Details'
              }
              variant="roundedButton"
              onClick={() => {
                props.handleViewDetails();
              }}
            />
          </Grid>
        </Grid>
      </CardActions>
    </React.Fragment>
  );

  return (
    <Card variant="outlined" className={classes.container}>
      {isMobileScreen ? <MobilePaymentCard {...props} /> : cardChanged}
    </Card>
  );
}
