import { Box } from "@mui/system";
import styled from "styled-components";

const StyledBox = styled(Box)((theme) => ({
  color: "red",
}));
export default function KenError({ errors, field, type, message, single }) {
  if (single && message) {
    return (
      <StyledBox component="span">
        {message || "Please enter valid input"}
      </StyledBox>
    );
  }

  return (
    <StyledBox component="span">
      {errors[field] && errors[field].type === type ? message : ""}
    </StyledBox>
  );
}
