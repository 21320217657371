import React from "react";
import { makeStyles } from "@mui/styles";
import KenTextLabel from "../KenTextLabel";
import KenError from "../KenError";
import PhoneInput from "react-phone-input-2";

const useStyles = makeStyles((theme) => ({
  inputFieldLabel: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "100%",
    textTransform: "capitalize",
    color: "#505F79",
  },
  phoneContainer: {
    display: "flex",
    "& .phone-input": {
      fontFamily: "Poppins,sans-serif",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "100%",
      color: "#505F79",
      padding: "16px",
      order: 2,
      background: "#F3F5F7",
      border: 0,
      height: "48px",
      //   57
      borderRadius: "4px",
      marginLeft: "11px",
      "&::placeholder": {
        fontSize: "16px",
        lineHeight: "100%",
        color: "#A8AFBC",
        fontFamily: "Poppins,sans-serif",
      },
    },
    "& .phone-dropdown": {
      position: "relative",
      order: 1,
      border: 0,
      background: "#F3F5F7",
      "&.open": {
        background: "#F3F5F7",
        "& .selected-flag": {
          background: "#F3F5F7",
        },
      },
      "& .selected-flag": {
        width: "50px",
        padding: "10px",
        "&:hover": {
          background: "#F3F5F7",
        },
      },
    },
  },
}));

export default function KenPhoneInput(props) {
  const classes = useStyles();

  return (
    <>
      <KenTextLabel
        label="Phone Number"
        labelClassName={`${classes.inputFieldLabel} ${
          props.inputFieldLabelClass ? props.inputFieldLabelClass : ""
        }`}
        required={true}
      />
      <PhoneInput
        {...props}
        containerClass={`${classes.phoneContainer} ${
          props.phoneContainerClass ? props.phoneContainerClass : ""
        }`}
        inputClass={`${classes.inputFieldLabel} phone-input`}
        buttonClass="phone-dropdown"
        country={"in"}
        placeholder="Enter Number"
        onChange={(phone) => {
          if (typeof props.onChange === "function") {
            if (phone.includes("+")) {
              props.onChange(phone);
            } else {
              props.onChange(`+${phone}`);
            }
          }
        }}
      />
      {props?.error &&
        props?.error?.type === "validate" &&
        props?.error?.message && (
          <KenError message={props?.error?.message} single />
        )}
    </>
  );
}
