import { Fade, Grid, Box, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadIcon from "@mui/icons-material/Upload";
import KenDialog from "../../../../Components/KenDialog";
import { useDropzone } from "react-dropzone";
import KenFilePreview from "../../../../Components/KenDocumentUpload/Components/KenFilePreview";
import { TABLET_BREAKPOINT } from "../../../../Constants/constant";
import Menu from '@mui/material/Menu';
import { saveAs } from 'file-saver';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Eye from '../../../../Assets/Svg/eye.svg';
import Download from '../../../../Assets/Svg/download.svg';
import Replace from '../../../../Assets/Svg/replace.svg';

const convertBase64ToFile = (base64String, fileName) => {
  let arr = base64String.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let uint8Array = new Uint8Array(n);
  while (n--) {
    uint8Array[n] = bstr.charCodeAt(n);
  }
  let file = new File([uint8Array], fileName, { type: mime });
  return file;
}

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#F7F8FB",
    padding: "8px 16px",
    borderRadius: 4,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '4px',
    }
  },
  documentLabel: {
    float: "left",
    textAlign: "left",
    color: theme.palette.KenColors.grey2,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '50%',
      'word-break': 'break-word',
    }
  },
  icon: {
    color: theme.palette.KenColors.grey3,
    "&:hover": {
      cursor: "pointer",
    },
  },
  status: {
    color: theme.palette.KenColors.kenWhite,
    padding: "4px 14px",
    borderRadius: 4,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '4px',
      fontSize: '10px',
    }
  },
  uploadLabel: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '14px',
      'line-height': '100%',
      'color': theme.palette.KenColors.gradeSectionHeaderLabel,
      minWidth: '164px',
      display: 'inline-block'
    }
  },
  menuItem: {
    display: 'flex',
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    'color': '#505F79',
    '& > img': {
      marginRight: '8px',
    }
  },
  moreOptions: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '0px !important'
    }
  }
}));

const getStatus = ({ isMobileScreen, status, classes, getRootProps, getInputProps, open }) => {
  return !status && isMobileScreen ? <Grid item {...getRootProps({ className: "dropzone" })}>
    <input {...getInputProps()} />
    <span onClick={open} className={classes.uploadLabel}>Upload Document</span>
  </Grid>
    : <Grid item md={3} xs={12}>
      <Typography
        variant="body4"
        className={status !== null && classes.status}
        sx={
          status === "Accepted" && status !== null
            ? { background: "#4BB99A" }
            : status === "Pending Verification"
              ? { background: "#2862FF", opacity: "0.6" }
              : status === "Rejected"
                ? { background: "#FE7E6D" }
                : ""
        }
      >
        {status || "---"}
      </Typography>
    </Grid>
}
const ITEM_HEIGHT = 48;

export default function DocumentList(props) {
  const { onChange, value = [], title, fileName, previewImage, status } = props;
  const [filePreview, setFilePreview] = useState(false);
  const [url, setUrl] = useState();
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(
    TABLET_BREAKPOINT
  ));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      onChange([...value, ...acceptedFiles]);
    },
  });

  React.useEffect(() => {
    if (!(previewImage instanceof File)) {
      setUrl(previewImage);
    }
  }, [previewImage]);

  return (
    <Grid
      container
      spacing={1}
      mt={1}
      mb={1}
      justifyContent="left"
      className={classes.container}
    >
      <Grid item md={3} xs={12}>
        <Typography variant="body5" className={classes.documentLabel}>
          {title}
        </Typography>
        {isMobileScreen ? <Box
          display='flex'
          justifyContent='center'
          alignItems='flex-start'
        >
          {getStatus({ isMobileScreen, status, classes, getRootProps, getInputProps, open })}
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={menuOpen ? 'long-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.moreOptions}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                handleClose();
                if (previewImage !== null) {
                  setFilePreview(true);
                }
              }}>
              <img src={Eye} alt="view"
                className={classes.mobileMenuIcon}
              />
              <label>View</label>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                handleClose();
                try {
                  if (!!fileName) {
                    let file = convertBase64ToFile(previewImage, fileName);
                    saveAs(file, fileName);
                  }
                } catch (e) { }
              }}
            >
              <img src={Download} alt="view"
                className={classes.mobileMenuIcon}
              /><label>Download</label></MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                handleClose();
                open();
              }}
            >
              <img src={Replace} alt="view"
                className={classes.mobileMenuIcon}
              />
              <Grid item {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <span><label>Replace Document</label></span>
              </Grid>
            </MenuItem>
          </Menu>
        </Box> : null}
      </Grid>
      <Grid item md={4} xs={12} textAlign={isMobileScreen ? 'left' : undefined}>
        {fileName?.length > 35 ? (
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={fileName}
          >
            <Typography variant="body4">
              {`${fileName?.substring(0, 18)}..` || "---"}
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="body4">{fileName || "---"} </Typography>
        )}
      </Grid>
      {!isMobileScreen ? getStatus({ isMobileScreen, status, classes, getRootProps, getInputProps, open }) : null}
      {!isMobileScreen &&
        <Grid item md={2} xs={12}>
          <Grid container justifyContent="right" spacing={2}>
            <Grid item>
              <FileDownloadIcon className={classes.icon} onClick={() => {
                try {
                  if (!!fileName) {
                    let file = convertBase64ToFile(previewImage, fileName);
                    saveAs(file, fileName);
                  }
                } catch (e) { }
              }} />
            </Grid>
            <Grid item>
              <VisibilityIcon
                className={classes.icon}
                onClick={() => {
                  if (previewImage !== null) {
                    setFilePreview(true);
                  }
                }}
              />
            </Grid>
            <Grid item {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <UploadIcon onClick={open} className={classes.icon} />
            </Grid>
          </Grid>
        </Grid>
      }
      <KenDialog
        open={filePreview}
        handleClose={() => {
          setFilePreview(false);
        }}
      >
        <KenFilePreview
          remove={false}
          width="100%"
          type={"file"}
          file={previewImage instanceof File && previewImage}
          url={url}
        />
      </KenDialog>
    </Grid>
  );
}
