import React, { useContext, useEffect, useState } from "react";
import KenStepperHeader from "../KenStepperHeader";
import KenStepperContent from "../KenStepperContent";
import KenStepperFooter from "../KenStepperFooter";
import { getStepByPosition } from "../Helpers/formHelper";
import { Box } from "@mui/system";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { makeStyles } from "@mui/styles";
import KenApplicationFormHeader from "../../../KenApplicationFormHeader";
import ThankYouPage from "../ThankYouPage";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormContext } from "../../../../Context/formContext";
import Instructions from "../InstructionsPage";
import InstructionsFooter from "../InstructionsPage/footer";
import KenButton from "../../../KenButton";
import { TABLET_BREAKPOINT } from "../../../../Constants/constant";

const useStyles = makeStyles((theme) => ({
	header: {
		background: "#FFFFFF",
		boxShadow:
			"0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
	},
	thankYouContainer: {
		[theme.breakpoints.down(TABLET_BREAKPOINT)]: {
			width: '80%',
			'position': 'absolute',
			'top': '50%',
			'left': '50%',
			'transform': 'translate(-50%, -50%)',
		}
	},
	title: {
		[theme.breakpoints.down(TABLET_BREAKPOINT)]: {
			'font-style': 'normal',
			'font-weight': '500',
			'font-size': '20px',
			'line-height': '23px',
			'color': '#000000',
		}
	},
	subTitle: {
		[theme.breakpoints.down(TABLET_BREAKPOINT)]: {
			'font-style': 'normal',
			'font-weight': '400',
			'font-size': '16px',
			'line-height': '150%',
			'text-align': 'center',
			'color': '#505F79',
		}
	},
	instructionContainer: {
		[theme.breakpoints.down(TABLET_BREAKPOINT)]: {
			padding: '0px',
			marginBottom: '60px'
		}
	},
	nextBtn: {
		marginRight: theme.spacing(28),
		'display': 'flex',
		'flex-direction': 'row',
		'justify-content': 'center',
		'align-items': 'center',
		'padding': '8px',
		'gap': '8px',
		'width': '140px',
		'height': '48px',
		'background': '#F6C445',
		'border-radius': '4px',
		'font-style': 'normal',
		'font-weight': '500 !important',
		'font-size': '18px !important',
		'line-height': '100% !important',
		'text-align': 'center',

		[theme.breakpoints.down(TABLET_BREAKPOINT)]: {
			marginRight: '0px',
			width: 80,
		}
	},
}));

function KenFormBuilder(props) {
	const { formJSON, currentActiveStep, setCsc } = props;
	const { formState, formDispatch } = useContext(FormContext);
	const classes = useStyles();
	const [currentStepDetails, setCurrentStepDetails] = useState();
	const [programDetails, setProgramDetails] = useState();
	const [showInstructions, setShowInstructions] = useState(false);
	// console.log("currentStep", currentStepDetails);
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down(TABLET_BREAKPOINT));
	//Stepper header states
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});
	const [visibleSteps, setVisibleSteps] = React.useState([...formJSON]);
	const [showThankYou, setShowThankYou] = React.useState(false);
	const [saveFlag, setSaveFlag] = React.useState(null);

	const [campusName] = React.useState(
		formState?.formData?.application?.campusName
	);
	const [specializationName] = React.useState(
		formState?.formData?.application?.specializationName
	);
	const [departmentName] = React.useState(
		formState?.formData?.programDetails?.departmentName
	);
	const [programName] = React.useState(
		formState.formData.programDetails?.programName
	);

	//Stepper header methods
	const totalSteps = () => {
		return visibleSteps.length;
	};

	const completedSteps = () => {
		// console.log("completed", Object.keys(completed).length);
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	// const handleNext = () => {
	// 	const newActiveStep =
	// 		isLastStep() && !allStepsCompleted()
	// 			? // It's the last step, but not all steps have been completed,
	// 			  // find the first step that has been completed
	// 			  visibleSteps.findIndex((step, i) => !(i in completed))
	// 			: activeStep + 1;
	// 	setActiveStep(newActiveStep);
	// };
	const handleNext = () => {
		const newActiveStep = isLastStep()
			? // It's the last step, but not all steps have been completed,
			// find the first step that has been completed
			handleStepsSubmit()
			: activeStep + 1;

		setActiveStep(newActiveStep);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	const handleComplete = () => {
		const newCompleted = completed;
		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		handleNext();
	};
	const handleSave = () => {
		setSaveFlag(!saveFlag);
	};
	const handleStepsSubmit = () => {
		setShowThankYou(true);
	};

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	//To get updated/current step details
	useEffect(() => {
		const currentStep = getStepByPosition(visibleSteps, activeStep);
		setCurrentStepDetails(currentStep);
	}, [visibleSteps, activeStep]);

	//Set active step as currentStep
	useEffect(() => {
		if (currentActiveStep) {
			setActiveStep(parseInt(currentActiveStep));
		} else {
			setShowInstructions(true);
		}
	}, [currentActiveStep]);

	useEffect(() => {
		const visibleStepsOnly = visibleSteps?.filter(
			(step) => step.stepVisible === true
		);
		setVisibleSteps(visibleStepsOnly);
	}, [formJSON]);

	useEffect(() => {
		// console.log("formState", formState);
		setProgramDetails(formState?.formData?.programDetails);
	}, [formState]);

	const getProgramDetails = (a, b, c, d) => {
		let comma = ",";
		let str = " ";
		if (a) {
			if (b || c || d) {
				str = str + a + comma;
			} else {
				str = str + a;
			}
		}
		if (b) {
			if (c || d) {
				str = str + " " + b + comma;
			} else {
				str = str + " " + b;
			}
		}
		if (c) {
			if (d) {
				str = str + " " + c + comma;
			} else {
				str = str + " " + c;
			}
		}
		if (d) {
			str = str + " " + d;
		}
		return str;
	};

	return (
		<>
			<KenApplicationFormHeader
				{...programDetails}
				duration={programDetails?.courseDuration}
				batch={programDetails?.courseBatch || programDetails?.batch}
			/>

			{!showThankYou ? (
				<>
					{!showInstructions ? (
						<>
							<KenStepperHeader steps={visibleSteps} activeStep={activeStep} />

							<KenStepperContent
								totalSteps={totalSteps}
								formJSON={visibleSteps}
								activeStep={activeStep}
								currentStepDetails={currentStepDetails}
								completedSteps={completedSteps}
								handleComplete={handleComplete}
								handleBack={handleBack}
								handleSave={handleSave}
								saveFlag={saveFlag}
								handleNext={handleNext}
								completed={completed}
								allStepsCompleted={allStepsCompleted}
								handleStepsSubmit={handleStepsSubmit}
								setCsc={setCsc}
							/>
						</>
					) : (
						<>
							<Box padding='0px 223px' className={classes.instructionContainer}>
								<Instructions />
							</Box>

							<InstructionsFooter>
								<KenButton
									buttonClass={classes.nextBtn}
									variant='contained'
									endIcon={isMobileScreen ? null : <ArrowForwardOutlinedIcon />}
									onClick={() => setShowInstructions(false)}
								>
									Next
								</KenButton>
							</InstructionsFooter>
						</>
					)}
				</>
			) : (
				<Box
					margin='auto'
					height='90%'
					p={2}
					display='flex'
					justifyContent='center'
					alignContent='center'
					flexDirection='column'
				>
					<ThankYouPage
						wrapperClassName={classes.thankYouContainer}
						titleClass={classes.title}
						subTitleClass={classes.subTitle}
						title='Thank you for your Application'
						description={
							<Typography>
								You have successfully Applied for
								<strong>
									{getProgramDetails(
										campusName,
										specializationName,
										departmentName,
										programName
									)}
								</strong>
							</Typography>
						}
					/>
				</Box>
			)}
		</>
	);
}
export default KenFormBuilder;
