import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useApplicationContext } from '../../Context/applicationContext';
import { createUpdateApplication } from '../../Utils/apiServices';
import Instructions from '../KenApplicationForm/Components/InstructionsPage';
import KenStepperFooter from '../KenApplicationForm/Components/KenStepperFooter';
import Footer from './Footer';

export default function InstructionsStep() {
  const [searchParams, setSearchParams] = useSearchParams();

  // Application context to update the applicationId, data and active step
  const {
    applicationState: { formId, form, applicationId, applicantId },
    applicationDispatch,
  } = useApplicationContext();

  // Create the application on next and update the url with applicationId params
  const handleNext = () => {
    console.log('====> ', {
      applicationId: applicationId,
      application: {
        academicCycle: form.academicCycle,
        programId: form.programId,
        programName: form.programName,
        applicantId: applicantId,
        applicationForm: form.recordId,
        currentStep: 0,
      },
    });

    // Create application
    createUpdateApplication({
      applicationId: applicationId,
      application: {
        academicCycle: form.academicCycle,
        programId: form.programId,
        programName: form.programName,
        applicantId: applicantId,
        applicationForm: form.recordId,
        currentStep: 0,
      },
    })
      .then((res) => {
        // update url - must for proper functions on reload and applicationContect
        if (res.success) {
          applicationDispatch({
            activeStep: 0,
            applicationId: res.applicationId,
            applicationData: res?.data,
          });
          setSearchParams({
            formId: formId,
            applicationId: res.applicationId,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <div style={{ marginBottom: 70 }}>
        <Instructions />
      </div>
      {/* <Footer isSave={false} isPrev={false} onNext={handleNext} /> */}
      <KenStepperFooter isSave={false} isPrev={false} onNext={handleNext} />
    </div>
  );
}
