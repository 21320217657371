import React from "react";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import KenUploadButton from "./Components/KenUploadButton";
import FilePreview from "./Components/KenFilePreview";

const useStyles = makeStyles((theme) => ({
	card: {
		margin: 16,
	},
	cardContainer: {
		padding: 8,
	},
	rowWrap: {
		marginBottom: 32,
		// borderBottom: `1px solid ${theme.palette.KenColors.neutral60}`,
		paddingBottom: 14,
	},
	rowWrapConditional: {
		marginBottom: 0,
		borderBottom: 0,
	},
	rowLabel: {
		// color: theme.palette.KenColors.neutral900,
		// fontSize: 16,
		fontWeight: 500,
		color: "#091E42",
	},
	rowDesc: {
		// color: theme.palette.KenColors.neutral100,
		// fontSize: 14,
		maxWidth: "400px",
		color: "#7A869A",
	},
	mainContent: {
		position: "relative",
	},
}));

export default function KenDocumentUpload(props) {
	const { t } = useTranslation();
	const {
		value = [],
		label,
		description,
		onChange,
		length,
		index,
		uploading,
		name,
		removeFile,
		required,
		previewImage,
		preview = true,
	} = props;
	const { getRootProps, getInputProps, open } = useDropzone({
		noClick: true,
		multiple: false,
		noKeyboard: true,
		onDrop: (acceptedFiles) => {
			onChange([...value, ...acceptedFiles]);
		},
	});

	const classes = useStyles();
	const onError = (err) => console.log("Error:", err);

	return (
		<div
			className={clsx(classes.rowWrap, {
				[classes.rowWrapConditional]: length === index,
			})}
		>
			<Grid container {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} />
				<Grid xs={12} md={8}>
					<Typography align='left' className={classes.rowLabel} variant='h5'>
						{label}
						{required === true && <span style={{ color: "#E75547" }}>*</span>}
					</Typography>
					<Typography
						align='left'
						className={classes.rowDesc}
						variant='subtitle2'
					>
						{description}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					{!previewImage ? (
						<KenUploadButton open={open} />
					) : (
						<>
							{preview && (
								<div>
									<FilePreview
										type={"file"}
										file={previewImage}
										onError={onError}
										width={"210px"}
										height={"150px"}
										removeFile={removeFile}
									/>
								</div>
							)}
						</>
					)}
				</Grid>
			</Grid>
		</div>
	);
}
