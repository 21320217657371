import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import KenCircularProgressWithLabel from "../../../../Components/KenProgress/KenCircularProgressWithLabel";
import { useTranslation } from "react-i18next";
import { FormContext } from "../../../../Context/formContext";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    minHeight: "234px",
    background: theme.palette.card.backgroundColor,
    mixBlendMode: "normal",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)",
    borderRadius: "12px",
    padding: "16px",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.card.hover,
    },
  },

  title: {
    margin: "8px 0px 4px 0px",
    textAlign: "left",
    fontWeight: "500",
    fontSize: 18,
    lineHeight: "100%",
    marginBottom: 12,
  },
  applicationId: {
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.labels.medium,
  },
  infoLocation: {
    textAlign: "left",
    marginTop: "4px",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.labels.medium,
  },
  infoDate: {
    textAlign: "center",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "100%",
    textTransform: "capitalize",
    color: theme.palette.labels.main,
    marginTop: "24px",
  },
  btnApply: {
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.primary.main,
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  courseCount: {
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.labels.light,
    textAlign: "left",
  },
}));
export default function CoursesCardView(props) {
  const { data, colValue } = props;
  const { formState, formDispatch } = useContext(FormContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [active, setActive] = useState(null);

  const goToApplicationForm = (item) => {
    formDispatch({
      type: "new",
      payload: {
        programDetails: { ...item, formId: item.recordId },
      },
    });
    navigate({
      pathname: "/application",
      search: `?formId=${item?.recordId}&type=${item?.departmentName}`,
    });
  };
  const activeBorder = (active) => {
    setActive(active);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item md={12} sm={12} xs={12}>
          <Typography className={classes.courseCount}>
            {data.length} {t("labels:Courses")}
          </Typography>
        </Grid>
        {data &&
          data.map((item, index) => {
            return (
              <Grid item md={colValue} sm={6} xs={12}>
                <Box
                  className={classes.mainBox}
                  onClick={(e) => {
                    activeBorder(item.id);
                  }}
                  style={{
                    border: active === item.id ? "1px solid #092682 " : "",
                  }}
                  p={4}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={9} sm={9} md={9} wrap>
                      <Typography className={classes.title} gutterBottom>
                        {item.course}
                      </Typography>

                      <Typography className={classes.infoLocation} gutterBottom>
                        {item.courseDuration} | {item.courseBatch}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <Box display={"flex"} justifyContent="center">
                        {item?.status ? (
                          <KenCircularProgressWithLabel value={25} size={35} />
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <Box className={classes.infoDate}>
                        <Typography gutterBottom>
                          {t("labels:Last_date")}: {item.lastdate}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <Button
                        className={classes.btnApply}
                        onClick={() => goToApplicationForm(item)}
                      >
                        {item?.status
                          ? t("labels:Complete_Application")
                          : t("labels:Apply")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
}
