import { Box, Button, Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import KenCircularProgressWithLabel from "../../Components/KenProgress/KenCircularProgressWithLabel";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useAppContext } from "../../Context/appContext";
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import calendar from '../../Assets/Svg/calendar-2.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.KenColors.card,
    borderRadius: 12,
    height: "100%",
    // maxHeight: 287,
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)",
    "&:hover": {
      background: "#EDF2FF",
    },
  },
  titleTypo: {
    color: theme.palette.KenColors.dark,
    fontWeight: 500,
    fontSize: 16,
    textAlign: "left",
  },
  departmentName: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '100%',
    color: theme.palette.KenColors.grey2,
    marginBottom: 0,
    textAlign: 'left',
  },
  applicationNoTypo: {
    fontWeight: 500,
    fontSize: 14,
    textAlign: "left",
    color: theme.palette.KenColors.grey2,
  },
  applicationDetails: {
    fontSize: 14,
    textAlign: "left",
    color: theme.palette.KenColors.grey3,
  },
  lastDate: {
    fontSize: 14,
    textAlign: "left",
    color: theme.palette.KenColors.dark,
  },
  lastDateInfo: {
    textAlign: "left",
    color: theme.palette.KenColors.tertiaryRed503,
  },
  icon: {
    height: 14,
    marginRight: theme.spacing(1),
  },
  btnApply: {
    fontWeight: 600,
    fontSize: 14,
    maxHeight: theme.spacing(4),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'line-height': '120%',
      paddingBottom: 0,
    }
  },
  titleContainer: {
    marginLeft: "-16px",
    marginRight: "16px",
    position: 'absolute',
    height: '48px',
    top: '24px',
    left: '-8px',
  },
  mobileCard: {
    display: 'flex',
    background: '#F7F8FB',
    borderRadius: '12px',
    minHeight: '140px',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
  leftSection: {
    width: '65%',
  },
  rightSection: {
    width: '35%',
    alignItems: 'flex-end !important',
  },
  mobileCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  title: {
    'font-style': 'normal',
    'font-weight': '600',
    'font-size': '18px',
    'line-height': '100%',
    'color': '#061938',
    textAlign: 'left',
    position: 'relative',
  },
  indicator: {
    'height': 'calc(100% + 16px)',
    'position': 'absolute',
    'top': 0,
    'left': '-16px',
  },
  mobileDateDetail: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',

    '& > .years': {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '14px',
      'line-height': '100%',
      color: theme.palette.KenColors.neutral400,
      marginBottom: theme.spacing(1.5)
    },

    '& > .lastDate': {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '14px',
      'line-height': '100%',
      'text-transform': 'capitalize',
      color: theme.palette.KenColors.neutral400,

      '& > span': {
        'font-style': 'normal',
        'font-weight': '500',
        'font-size': '14px',
        'line-height': '100%',
        'text-transform': 'capitalize',
        color: theme.palette.KenColors.neutral900,
      }
    },
  },
  noStatus: {
    justifyContent: 'flex-end !important',
  },
  status: {
    'display': 'flex',
    'flex-direction': 'column',
    'justify-content': 'space-between',
    'align-items': 'center',
    'height': '100%',
  },
  progressLabelClassName: {
    'font-style': 'normal',
    'font-weight': '600',
    'font-size': `${theme.spacing(1.5)} !important`,
    'line-height': '100%',
    'display': 'flex',
    'align-items': 'center',
    color: theme.palette.KenColors.grey2,
  }
}));

export default function ApplicationCard(props) {
  const { item, setApplicationStatus, goToApplicationForm, setActive, active } =
    props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(
    TABLET_BREAKPOINT
  ));
  const {
    state: { config },
  } = useAppContext();

  return isMobileScreen ? <MobileApplicationCard {...props} /> :
    <Grid
      container
      item
      md={12}
      direction="column"
      justifyContent="space-between"
      className={classes.container}
      onClick={(e) => {
        setActive(item.recordId);
        if (item?.applicationStatus) {
          setApplicationStatus(item?.applicationStatus);
        }
      }}
      style={{
        border: active === item.recordId ? "1px solid #0047AB" : "",
      }}
      sx={{ padding: theme.spacing(2) }}
    >
      <Grid item sx={{ marginBottom: theme.spacing(2) }}>
        <>
          <Grid container direction="column" spacing={1} sx={{ marginLeft: 0, marginTop: 0, width: '100%' }}>
            <Grid item container direction="row" justifyContent="space-between" sx={{ paddingTop: '0 !important' }}>
              <Grid item container xs={item.applicationStatus ? 9 : 12}
                wrap
                flexDirection="column"
                position="relative"
                alignItems="flex-start"
                sx={{ paddingTop: theme.spacing(1) }}
              >
                <Grid
                  item
                  className={classes.titleContainer}
                  style={
                    !item?.applicationStatus ? { borderLeft: "5px solid #E2E2E2" } :
                      item.applicationStatus !== "Application form Incomplete"
                        ? { borderLeft: "5px solid #25AE88" }
                        : { borderLeft: "5px solid #EF4060" }
                  }
                ></Grid>
                <Grid item mb={theme.spacing(1.5)}>
                  <Typography className={classes.titleTypo}>
                    {item.programName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.departmentName} gutterBottom>
                    {item.departmentName}
                  </Typography>
                </Grid>
              </Grid>
              {item.applicationStatus && (
                <Grid item>
                  <KenCircularProgressWithLabel
                    progressLabelClassName={classes.progressLabelClassName}
                    value={
                      item.applicationStatus !== "Application form Incomplete"
                        ? 100
                        : (item.currentStep / config.totalSteps) * 100 || 0
                    }
                    size={48}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item paddingTop={`${theme.spacing(1.5)} !important`}>
              <Divider variant="fullWidth" style={{ background: '#E2E2E2' }} />
            </Grid>
            {item?.applicationNumber && <Grid item paddingTop={`${theme.spacing(1.5)} !important`} sx={{ height: 26 }}>
              <Typography className={classes.applicationNoTypo}>
                {item.applicationNumber}
              </Typography>
            </Grid>}
            <Grid item paddingTop={`${theme.spacing(1.5)} !important`} sx={{ height: 26 }}>
              <Typography className={classes.applicationDetails}>
                {item.courseDuration && `${item.courseDuration} |`}{" "}
                {item.courseBatch}
              </Typography>
            </Grid>
            <Grid item container direction="row" paddingTop={`${theme.spacing(4)} !important`}>
              <Grid item sx={{ height: 18 }}>
                <img src={calendar} className={classes.icon} alt="" />
              </Grid>
              <Grid item>
                <Typography className={classes.lastDate}>
                  Last date:{" "}
                  <span className={classes.lastDateInfo}>
                    {item.endDate || item.courseEndDate}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      </Grid>
      <Grid item>
        <Button
          className={classes.btnApply}
          onClick={() => goToApplicationForm(item)}
        >
          {item.applicationStatus
            ? item?.applicationStatus === "Application form Incomplete"
              ? "Complete Application"
              : "View Application"
            : "Apply"}
        </Button>
      </Grid>
    </Grid >
}

export function MobileApplicationCard(props) {
  const { item, setApplicationStatus, goToApplicationForm, setActive, active } =
    props;
  const classes = useStyles();
  const {
    state: { config },
  } = useAppContext();

  return (
    <Box className={classes.mobileCard}
      onClick={(e) => {
        setActive(item.recordId);
        if (item?.applicationStatus) {
          setApplicationStatus(item?.applicationStatus);
        }
      }}
      style={{
        border: active === item.recordId ? "1px solid #0047AB" : "",
      }}
    >
      <Box className={`${classes.mobileCardDescription} ${classes.leftSection}`}>
        <Typography className={classes.title}>
          {item.programName}
          <Grid
            item
            className={classes.indicator}
            style={
              !item?.applicationStatus ? { borderLeft: "5px solid #E2E2E2" } :
                item.applicationStatus !== "Application form Incomplete"
                  ? { borderLeft: "5px solid #25AE88" }
                  : { borderLeft: "5px solid #EF4060" }
            }
          ></Grid>
        </Typography>
        <Box className={classes.mobileDateDetail}>
          <Typography className='years'>
            {item.courseDuration && `${item.courseDuration} |`}{" "}
            {item.courseBatch}
          </Typography>
          <Box className="lastDate">
            Last date: <span className={classes.lastDateInfo}>
              {item.endDate || item.courseEndDate}
            </span></Box>
        </Box>
      </Box>
      <Box className={`${classes.mobileCardDescription} ${classes.rightSection}`}>
        <Box className={`${classes.status} ${!item.applicationStatus ? classes.noStatus : ''}`}>
          {item.applicationStatus && (
            <KenCircularProgressWithLabel
              progressLabelClassName={classes.progressLabelClassName}
              value={
                item.applicationStatus !== "Application form Incomplete"
                  ? 100
                  : (item.currentStep / config.totalSteps) * 100 || 0
              }
              size={48}
            />
          )}
          <Box>
            <Button
              className={classes.btnApply}
              onClick={() => goToApplicationForm(item)}
            >
              {item.applicationStatus
                ? item?.applicationStatus === "Application form Incomplete"
                  ? "Complete Application"
                  : "View Application"
                : "Apply Now"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
    // <Grid
    //   container
    //   item
    //   md={12}
    //   direction="column"
    //   justifyContent="space-between"
    //   className={classes.container}
    //   onClick={(e) => {
    //     setActive(item.recordId);
    //     if (item?.applicationStatus) {
    //       setApplicationStatus(item?.applicationStatus);
    //     }
    //   }}
    //   style={{
    //     border: active === item.recordId ? "1px solid #0047AB" : "",
    //   }}
    //   sx={{ padding: "24px 16px" }}
    // >
    //   <Grid item>
    //     <>
    //       <Grid container direction="column" spacing={1}>
    //         <Grid item container direction="row" justifyContent="space-between">
    //           <Grid item container xs={item.applicationStatus ? 9 : 12} wrap>
    //             {item.applicationStatus && (
    //               <Grid
    //                 item
    //                 className={classes.titleContainer}
    //                 style={
    //                   item.applicationStatus !== "Application form Incomplete"
    //                     ? { borderLeft: "5px solid #25AE88" }
    //                     : { borderLeft: "5px solid #EF4060" }
    //                 }
    //               ></Grid>
    //             )}
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //     </>
    //   </Grid>
    // </Grid>
  );
}
