import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import KenCircularProgressWithLabel from '../../../../Components/KenProgress/KenCircularProgressWithLabel.jsx';
import moment from 'moment';
import { FormContext } from '../../../../Context/formContext';
import ApplicationCard, {
  MobileApplicationCard,
} from '../../../../Cards/ApplicationCard/index.jsx';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant.js';
import NewApplication from '../../../../Assets/Svg/new-application.svg';
import NewApplication1 from '../../../../Assets/Svg/new-application1.svg';

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'left',
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
    textTransform: 'uppercase',
    color: '#20365C',
    position: 'relative',

    '& > .underline': {
      position: 'absolute',
      width: '55px',
      height: '4px',
      top: '24px',
      left: 0,
      background: theme.palette.KenColors.statusYellow,
    },
  },
  subHeading: {
    textDecoration: 'none',
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
    textTransform: 'capitalize',
    color: '#061938',
  },
  mainBox: {
    minHeight: '216px',
    background: '#F7F8FB',
    mixBlendMode: 'normal',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '12px',
    padding: '16px',
    cursor: 'pointer',
    marginTop: '0px !important',
    marginLeft: '0px !important',
    width: '100% !important',
    '&:hover': {
      background: '#EDF2FF',
    },

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      minHeight: '140px',
      cursor: 'auto',
      boxShadow: 'none',
      mixBlendMode: 'unset',
      padding: '16px 12px',
      justifyContent: 'space-between',
      margin: 0,
    },
  },

  title: {
    marginBottom: 12,
    marginTop: '8px',
    textAlign: 'left',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: '100%',
  },
  applicationId: {
    textAlign: 'left',
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '100%',
    color: '#505F79',
  },
  infoLocation: {
    textAlign: 'left',
    marginTop: '4px',
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    color: '#505F79',
  },
  infoDate: {
    textAlign: 'center',
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    textTransform: 'capitalize',
    color: '#061938',
    marginTop: '24px',
  },
  btnApply: {
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    color: '#092682',
    flex: 'none',
    order: '0',
    flexGrow: '0',
  },
  courseCount: {
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '100%',
    color: '#7A869A',
    textAlign: 'left',
  },
  typoOtherCourse: {
    marginBottom: '60px',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '100%',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginBottom: 0,
    },
  },
}));

export function ExtraCourseCard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  return (
    <Grid
      container
      spacing={2}
      p={2}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      className={classes.mainBox}
      width={isMobileScreen ? '100% !important' : undefined}
    >
      <Grid
        item
        paddingTop={isMobileScreen ? '0px !important' : undefined}
        sx={{
          'flex-grow': '1',
          'justify-content': 'center',
          'align-items': 'center',
          display: 'flex',
        }}
      >
        <img sx={{ height: '90px' }} src={NewApplication1} alt="" />
      </Grid>
      <Grid item>
        <Button
          className={classes.btnApply}
          onClick={() => {
            navigate('/courses');
          }}
        >
          Start New Application
        </Button>
      </Grid>
    </Grid>
  );
}

export default function CoursesBox(props) {
  const { programs, applicationType, setApplicationStatus } = props;
  const classes = useStyles();
  const { formState, formDispatch } = useContext(FormContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  const [active, setActive] = useState('');
  const navigate = useNavigate();
  const goToApplicationForm = (item) => {
    formDispatch({
      type: 'new',
      payload: {
        programDetails: item,
        application: {
          ...formState.formData?.application,
          programId: item.programId,
          programName: item.programName,
        },
      },
    });
    const appId =
      applicationType === 'MyApplication'
        ? `&applicationId=${item.recordId}`
        : '';
    navigate({
      pathname: "/application",
      search: `?formId=${applicationType === "MyApplication"
        ? item?.applicationForm
        : item?.recordId
        }${appId}&type=${item?.departmentName}`,
    });
  };

  return (
    <Grid
      item
      container
      spacing={2}
      direction="column"
      width={isMobileScreen ? '100% !important' : undefined}
    >
      <Grid item md={12} paddingBottom={theme.spacing(0.5)}>
        <Grid
          container
          spacing={2}
          justifyContent={isMobileScreen ? 'space-between' : undefined}
          alignItems={isMobileScreen ? 'center' : undefined}
          paddingLeft={isMobileScreen ? '16px' : undefined}
        >
          <Grid
            item
            md={8}
            paddingLeft={isMobileScreen ? '0px !important' : undefined}
          >
            <Typography className={classes.heading}>
              {applicationType === 'Courses'
                ? `Our Courses(${programs?.length | 0})`
                : `My Application(${programs?.length | 0})`}
              <span className="underline"></span>
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            display="flex"
            justifyContent="flex-end"
            paddingLeft={isMobileScreen ? '0px !important' : undefined}
          >
            {applicationType === 'Courses' ? (
              <Link to="/courses" className={classes.subHeading}>
                View All
              </Link>
            ) : (
              <Link to="/my-applications" className={classes.subHeading}>
                View All
              </Link>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} sm={6} xs={12}>
        <Grid container spacing={2}>
          {programs &&
            programs.map((item, index) => {
              if (index <= 1) {
                return (
                  <Grid
                    container
                    paddingTop={isMobileScreen ? theme.spacing(2) : undefined}
                    item
                    md={4}
                    sm={12}
                    xs={12}
                  >
                    <ApplicationCard
                      item={item}
                      goToApplicationForm={goToApplicationForm}
                      setActive={setActive}
                      active={active}
                      setApplicationStatus={setApplicationStatus}
                    />
                  </Grid>
                  // <Grid item md={4} sm={12} xs={12}>
                  // <Box
                  //     className={classes.mainBox}
                  //     onClick={(e) => {
                  //       activeBorder(item.recordId);
                  //       setApplicationStatus(item?.applicationStatus);
                  //     }}
                  //     style={{
                  //       border:
                  //         active === item.recordId ? "1px solid #092682" : "",
                  //     }}
                  //     p={4}
                  //   >
                  //     <Grid container spacing={2}>
                  //       <Grid item xs={9} sm={9} md={9} wrap>
                  //         <Typography className={classes.title} gutterBottom>
                  //           {item.programName}
                  //         </Typography>

                  //         <Typography
                  //           className={classes.infoLocation}
                  //           gutterBottom
                  //         >
                  //           {item.courseDuration} | {item.courseBatch}
                  //         </Typography>
                  //       </Grid>
                  //       <Grid item xs={3} sm={3} md={3}>
                  //         <Box display={"flex"} justifyContent="center">
                  //           {item?.status ? (
                  //             <KenCircularProgressWithLabel
                  //               value={25}
                  //               size={35}
                  //             />
                  //           ) : (
                  //             ""
                  //           )}
                  //         </Box>
                  //       </Grid>
                  //       <Grid item xs={12} md={12} sm={12}>
                  //         <Box className={classes.infoDate}>
                  //           <Typography gutterBottom>
                  //             Last date: {item.endDate || item.courseEndDate}
                  //           </Typography>
                  //         </Box>
                  //       </Grid>
                  //       <Grid item xs={12} md={12} sm={12}>
                  //         <Button
                  //           className={classes.btnApply}
                  //           onClick={() => goToApplicationForm(item)}
                  //         >
                  //           {item.applicationStatus
                  //             ? item?.applicationStatus ===
                  //               "Application form Incomplete"
                  //               ? "Complete Application"
                  //               : "View Application"
                  //             : "Apply"}
                  //         </Button>
                  //       </Grid>
                  //     </Grid>
                  //   </Box>
                  // </Grid>
                );
              }
            })}
          <Grid item md={4} sm={12} xs={12}>
            <ExtraCourseCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
