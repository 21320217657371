import { Divider, Grid } from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import KenInput from '../../../../../KenInput';
import KenSelect, { CustomIcon } from '../../../../../KenSelect';
import KenPhoneInput from '../../../../../KenPhoneInput';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import KenRadioGroup from '../../../../../KenRadioGroup';
import KenDocumentUpload from '../../../../../KenDocumentUpload';
import { UploadDocumentContext } from '../../../../../../Context/uploadDocumentContext';
import { makeStyles } from '@mui/styles';
import KenFieldArray from '../../../../../KenFieldArray';
import { City, Country, State } from 'country-state-city';
import FieldArray from '../../../../../KenApplication/FieldArray';
import Calendar from '../../../../../../Assets/Svg/datepicker-calendar.svg';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  phoneContainerClass: {
    '& .phone-input': {
      height: '57px !important',
      width: '100%',
    },
  },
  inputFieldLabelClass: {
    color: '#505F79',
    'font-size': '12px',
    'line-height': '16px',
    'margin-bottom': '6px',
    'text-transform': 'capitalize',
    fontWeight: 500,
  },
  formFieldLabel: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    color: '#505F79',
  },
  datePickerClass: {
    '&  button.MuiIconButton-edgeEnd': {
      marginLeft: 0,
      marginRight: theme.spacing(1),
    },
  },
}));

const CustomCalendar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
        stroke="#73706E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 2V6"
        stroke="#73706E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 2V6"
        stroke="#73706E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 10H21"
        stroke="#73706E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const KenWidget = (props) => {
  const {
    ui, //TODO: ui.divider added only for select and input. Add it for all widgets.
    type,
    name,
    // label,
    description,
    fieldId,
    lastItem,
    // isFormValid,
    // options,
    validations = {},
    fieldsArray,
    // getFormValues,
  } = props;
  // TODO: Create a new component for upload and Move this context
  const { state, dispatch } = useContext(UploadDocumentContext);
  const classes = useStyles();
  const methods = useFormContext();
  const [objectName, setObjectName] = useState();
  const [fieldName, setFieldName] = useState();
  const errors = methods.formState.errors;
  //   console.log("errors in form", methods.formState.errors);
  const [size, setSize] = useState({
    xs: ui?.grid?.xs || 12,
    sm: ui?.grid?.sm || 6,
    md: ui?.grid?.md || 6,
    lg: ui?.grid?.lg || 6,
  });

  useEffect(() => {
    switch (ui?.gridType) {
      case 'fullRow':
        setSize({ xs: 12, sm: 12, md: 12, lg: 12 });

        break;

      case 'halfRow':
        setSize({ xs: 12, sm: 6, md: 6, lg: 6 });
        break;

      default:
        break;
    }
  }, [ui?.gridType]);

  const getSplicedName = (combinedName) => {
    const replaceBrackets = combinedName.replace(/[[\]']+/g, ' ');
    const splitStr = replaceBrackets?.split(' ');
    if (Array.isArray(splitStr) && splitStr.length > 0) {
      setObjectName(splitStr[0]);
      setFieldName(splitStr[1]);
    }
  };

  useEffect(() => {
    getSplicedName(name);
  }, [name]);

  useEffect(() => {
    // console.log("errors", errors);
    for (const [key, value] of Object.entries(errors)) {
      console.log(`${key}: ${value}`);
    }

    const isEmpty = Object.keys(errors).length === 0;
    if (isEmpty) {
      // isFormValid(true);
    } else {
      // isFormValid(false);
    }
  }, [errors]);

  const handleFileChange = (file, index) => {
    // console.log("file", file);
    dispatch({
      type: 'upload',
      payload: {
        id: fieldId,
        name: name,
        label: props.label,
        description: description,
        previewImage: file,
      },
    });
  };

  const handleFileDelete = (name) => {
    const index = state.newlyAddedFiles?.findIndex(
      (item) => item.name === name
    );
    dispatch({
      type: 'delete',
      payload: index,
    });
  };

  const getElementError = (data, name) => {
    const allData = Object.values(data);
    const index = allData?.findIndex((elem) => elem?.ref?.name === name);
    if (index > -1) {
      return allData[index]?.message;
    } else {
      return data?.message;
    }
  };

  // useEffect(() => {
  // 	if (type === "upload") {
  // 		dispatch({
  // 			type: "uploaded",
  // 			payload: {
  // 				id: fieldId,
  // 				name: name,
  // 				label: label,
  // 				description: description,
  // 				//  previewImage:file
  // 			},
  // 		});
  // 	}
  // }, []);

  const getPreviewImage = () => {
    const newFiles = state.newlyAddedFiles;
    const file = newFiles.find((item) => item.name === name);
    return file?.previewImage || false;
  };

  const getField = (type) => {
    switch (type) {
      case 'select':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <KenSelect
                textFieldProps={{
                  SelectProps: {
                    IconComponent: CustomIcon,
                  },
                }}
                {...props}
                labelClassName={classes.formFieldLabel}
                onChange={onChange}
                value={value}
                error={
                  methods.formState.errors &&
                  methods.formState.errors[objectName] &&
                  methods.formState.errors[objectName][fieldName] &&
                  getElementError(
                    methods.formState.errors[objectName][fieldName],
                    name
                  )
                }
              />
            )}
            {...methods.register(name, validations)}
          />
        );
      case 'input':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <>
                <KenInput {...props} {...field} error={error?.message} />
              </>
            )}
            {...methods.register(name, validations)}
          />
        );

      case 'textArea':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field }) => (
              <>
                <KenInput
                  {...props}
                  {...field}
                  labelClassName={classes.formFieldLabel}
                  multiline={true}
                  minRows={3}
                  error={
                    methods.formState.errors &&
                    methods.formState.errors[objectName] &&
                    methods.formState.errors[objectName][fieldName] &&
                    getElementError(
                      methods.formState.errors[objectName][fieldName],
                      name
                    )
                  }
                />
              </>
            )}
            {...methods.register(name, validations)}
          />
        );

      case 'phone':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <KenPhoneInput
                  phoneContainerClass={classes.phoneContainerClass}
                  inputFieldLabelClass={classes.formFieldLabel}
                  {...props}
                  onChange={onChange}
                  value={value}
                  error={
                    methods.formState.errors &&
                    methods.formState.errors[objectName] &&
                    methods.formState.errors[objectName][fieldName] &&
                    getElementError(
                      methods.formState.errors[objectName][fieldName],
                      name
                    )
                  }
                />
              );
            }}
            {...methods.register(name, validations)}
          />
        );
      case 'radio':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <KenRadioGroup
                {...props}
                labelClassName={classes.formFieldLabel}
                onChange={onChange}
                value={value}
                error={
                  methods.formState.errors &&
                  methods.formState.errors[objectName] &&
                  methods.formState.errors[objectName][fieldName] &&
                  getElementError(
                    methods.formState.errors[objectName][fieldName],
                    name
                  )
                }
              />
            )}
            {...methods.register(name, validations)}
          />
        );
      case 'upload':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <>
                <KenDocumentUpload
                  {...props}
                  onChange={(files) => {
                    handleFileChange(files[0], name);
                  }}
                  key={props?.fieldId}
                  previewImage={getPreviewImage()}
                  removeFile={() => {
                    handleFileDelete(name);
                  }}
                  // onChange={onChange}
                  value={value}
                />

                {(!lastItem || ui?.divider) && <Divider></Divider>}
              </>
            )}
            {...methods.register(name, validations)}
          />
        );

      case 'addMultiple': {
        return (
          <KenFieldArray
            {...props}
            // onChange={onChange}
            // value={value}
            bunchOfFields={fieldsArray}
            fieldArrayName={`${name}`}
            defaultObject={fieldsArray && fieldsArray[0]}
            error={
              methods.formState.errors &&
              methods.formState.errors[objectName] &&
              methods.formState.errors[objectName][fieldName] &&
              getElementError(
                methods.formState.errors[objectName][fieldName],
                name
              )
            }
            {...methods.register(name, validations)}
          />
          // <Controller
          // 	name={`${name}`}
          // 	control={methods.control}
          // 	render={({ field: { onChange, value } }) => (
          // 		<KenFieldArray
          // 			{...props}
          // 			onChange={onChange}
          // 			value={value}
          // 			bunchOfFields={fieldsArray}
          // 			fieldArrayName={`${name}`}
          // 			defaultObject={fieldsArray && fieldsArray[0]}
          // 		/>
          // 	)}
          // />
        );
      }

      case 'array':
        return <FieldArray {...props} />;

      case 'address':
        return <Address {...props} />;

      case 'date':
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <>
                <KenInput
                  {...props}
                  {...field}
                  disableKeyDown={true}
                  error={error?.message}
                />
              </>
            )}
            {...methods.register(name, validations)}
          />
        );

      default:
        return (
          <Controller
            name={`${name}`}
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <>
                <KenInput {...props} {...field} error={error?.message} />
              </>
            )}
            {...methods.register(name, validations)}
          />
        );
    }
  };

  return type !== 'addMultiple' && type !== 'address' && type !== 'array' ? (
    <Grid
      item
      xs={size.xs}
      sm={size.sm}
      md={size.md}
      lg={size.lg}
      textAlign="left"
    >
      {props && getField(type)}
    </Grid>
  ) : (
    <>{props && getField(type)}</>
  );
};
export default KenWidget;

const Address = function (props) {
  const { options: fields = [], name } = props;
  // handle all country, state,city, logic and options to hide

  // const [fields, setFields] = useState(fields);

  // watch country,state and update the relavent

  const { getValues } = useFormContext();

  const { mailingCountry, mailingState } = getValues(`${name}`) || {};
  const watchCountry = useWatch(`${name}`);

  const [country, setCountry] = useState();

  useEffect(() => {
    // setValue(`${name}['mailingState]`, '');
    // setValue(`${name}['mailingDistrict]`, '');
    //setCountryCode for state, city options

    const c = Country.getAllCountries().find(
      (el) => el.name === mailingCountry
    );

    setCountry(c);
  }, [mailingCountry]);

  useEffect(() => {
    // if dirty field
    console.log('useEffect>> watchCountry', watchCountry);
    // setValue(`${name}['mailingDistrict]`, '');
  }, [watchCountry]);

  const getOptions = ({ options, fieldId }) => {
    // if (options) {
    //   return options;
    // }

    // if country field return country options

    switch (fieldId) {
      case 'mailingCountry':
        return Country.getAllCountries().map(({ name: n }) => ({
          label: n,
          value: n,
        }));

      case 'mailingState':
        if (mailingCountry) {
          return State.getStatesOfCountry(country?.isoCode).map(({ name }) => ({
            label: name,
            value: name,
          }));
        }
        return [];

      case 'mailingDistrict':
        console.log('mailingDistrict: ', mailingState);
        if (mailingState) {
          const selectedStateCode = State.getAllStates().find(
            (el) => el.name === mailingState
          )?.isoCode;
          return City.getCitiesOfState(country?.isoCode, selectedStateCode).map(
            (el) => ({ label: el?.name, value: el?.name })
          );
        }
        return [];

      default:
        return [];
    }
  };

  return (
    <>
      {fields.map((field) => (
        <>
          {getOptions(field).length > 0 && (
            <KenWidget
              {...field}
              name={`${name}[${field?.name}]`}
              options={getOptions(field)}
            />
          )}
        </>
      ))}
    </>
  );
};
