import { Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Register from '../../../../Assets/SvgImages/Register.svg';
import SelectProgram from '../../../../Assets/SvgImages/SelectProgram.svg';
import FillForm from '../../../../Assets/SvgImages/FillForm.svg';
import Document from '../../../../Assets/SvgImages/Document.svg';
import Pay from '../../../../Assets/SvgImages/Pay.svg';
import Done from '../../../../Assets/Svg/done.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DoneIcon from '@mui/icons-material/Done';
import clsx from 'clsx';
import { useTheme } from '@emotion/react';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  title: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '18px',
    'line-height': '100%',
    'letter-spacing': '-0.02em',
    'text-transform': 'uppercase',
    color: theme.palette.KenColors.dark,
    display: 'flex',
    position: 'relative',

    '& > .underline': {
      position: 'absolute',
      'width': '55px',
      'height': '4px',
      top: '24px',
      'background': theme.palette.KenColors.statusYellow,
    },
  },
  wrapper: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      overflowX: "auto",
    },
  },
  container: {
    border: `1px solid ${theme.palette.KenColors.innerBorder}`,
    borderRadius: '12px',
    marginTop: theme.spacing(4.5),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '650px !important',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    '& .MuiStepConnector-horizontal': {
      display: 'none'
    },

    '& .MuiStepLabel-labelContainer': {
      width: 115,
      display: 'flex',
      justifyContent: 'center',
    },

    '& .MuiStepLabel-labelContainer > span': {
      'font-weight': '400',
      'font-size': '14px',
      'line-height': '150%',
      'text-align': 'center',
      color: theme.palette.KenColors.dark,
    },
  },
  Icon: {
    color: theme.palette.KenColors.grey5,
    marginTop: 32,
    margin: '0px 2px',
  },
  labelRoot: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.KenColors.dark,
  },
  stepRoot: {
    fontWeight: 12,
  },
  labelIcon: {
    background: theme.palette.KenColors.grey5,
  },
  completedIcon: {
    color: theme.palette.KenColors.kenWhite,
    'display': 'flex',
    'justify-content': 'center',
    'align-items': 'center',
  },
  completedIconContainer: {
    'display': 'flex',
    background: theme.palette.KenColors.darkGreen,
  },
  activeIconContainer: {
    background: theme.palette.KenColors.gradeSectionHeaderLabel,
    fontSize: 16,
    fontWeight: 500,
  },
  upcomingIcon: {
    color: theme.palette.KenColors.gradeSectionHeaderLabel,
    fontSize: 16,
    fontWeight: 500,
  },
  upcomingIconContainer: {
    border: `2px solid ${theme.palette.KenColors.gradeSectionHeaderLabel}`,
    background: theme.palette.KenColors.kenWhite,
  },
  verticalUpcomingIcon: {
    fontSize: 16,
    fontWeight: 500,
  },
  stepper: {
    position: 'relative',
    top: -13,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      top: '-21px',
      padding: 8,
    },
  },
}));

const applicationProcess = [
  {
    text: 'Register Yourself',
  },
  {
    text: 'Select The Program',
  },
  {
    text: 'Fill Application Form',
  },
  {
    text: 'Evaluation Process',
  },
  {
    text: 'Registration Process',
  },
];

const evaluationProcess = [
  {
    text: 'Our Application Process',
  },
  {
    text: 'Complete Registration for SPJAT',
  },
  {
    text: 'Pay SPJAT fee',
  },
  {
    text: 'Book your Interview Slot',
  },
  {
    text: 'Pay the Interview Fee',
  },
  {
    text: 'Upload your documents',
  },
  {
    text: 'Registration Process',
  },
];

const registrationProcess = [
  {
    text: 'Our Application Process',
  },
  {
    text: 'Evaluation Process',
  },
  {
    text: 'Pay Registration Fee',
  },
  {
    text: 'Download your Offer Letter',
  },
];

export default function ApplicationProcess(props) {
  const { applicationStatus } = props;
  const classes = useStyles();
  const [list, setList] = useState(applicationProcess);
  const [applicationProcessTitle, setApplicationProcessTitle] = useState(
    'Our Application Process'
  );
  const [activeStep, setActiveStep] = useState(1);
  const theme = useTheme();
  const screenSize = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (
      applicationStatus === 'Application form Incomplete' ||
      applicationStatus === 'Application form Submitted'
    ) {
      setApplicationProcessTitle('Our Application Process');
      setList(applicationProcess);
      console.log(applicationStatus);
      switch (applicationStatus) {
        case 'Application form Incomplete':
          setActiveStep(2);
          break;
        case 'Application form Submitted':
          setActiveStep(3);
          break;
        default:
      }
    } else if (
      applicationStatus === 'Shortlisted for SPJAT' ||
      applicationStatus === 'SPJAT Score obtained' ||
      applicationStatus === 'Shortlisted for Interview' ||
      applicationStatus === 'Interview score obtained' ||
      applicationStatus === 'Application Evaluation in process' ||
      applicationStatus === 'Selection Status' ||
      applicationStatus === 'Document Verification'
    ) {
      setApplicationProcessTitle('Evaluation Process');
      setList(evaluationProcess);
      switch (applicationStatus) {
        case 'Shortlisted for SPJAT':
          setActiveStep(0);
          break;
        case 'Shortlisted for Interview':
          setActiveStep(2);
          break;
        default:
      }
    } else if (applicationStatus === 'Main Offer Letter') {
      setApplicationProcessTitle('Registration Process');
      setList(registrationProcess);
      switch (applicationStatus) {
        case 'Shortlisted for SPJAT':
          setActiveStep(0);
          break;
        case 'Shortlisted for Interview':
          setActiveStep(2);
          break;
        default:
      }
    }
  }, [applicationStatus]);

  const CustomIcon = (props) => {
    const { index, activeStep } = props;
    return (
      <Grid
        item
        justifyContent="center"
        alignItems="center"
        sx={{ height: 26, width: 26, borderRadius: '50%' }}
        className={clsx(classes.labelIcon, {
          [classes.completedIconContainer]: index < activeStep,
          [classes.activeIconContainer]: index === activeStep,
          [classes.upcomingIconContainer]: index > activeStep,
        })}
      >
        {index < activeStep ? (
          <Grid item>
            <img src={Done} className={classes.completedIcon} sx={{ height: 18 }} alt="" />
          </Grid>
        ) : (
          <Grid
            item
            className={clsx(classes.completedIcon, {
              [classes.upcomingIcon]: index > activeStep,
              [classes.verticalUpcomingIcon]: screenSize === true,
            })}
          >
            {index + 1}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      <Grid item md={12} xs={12}>
        <Typography variant="body8" sx={{ float: 'left' }} className={classes.title}>
          {applicationProcessTitle}
          <span className='underline'></span>
        </Typography>
      </Grid>
      <Grid
        item
        container
        md={12}
        xs={12}
        direction="column"
        className={classes.wrapper}
      >

        <Grid container item md={12} xs={12} className={classes.container} maxWidth="100%" overflowX="auto">
          <Grid
            item
            md={12}
            minWidth={650}
            paddingBottom={theme.spacing(1)}
          >
            <Stepper
              classes={{ root: classes.stepper }}
              activeStep={activeStep}
              alternativeLabel={true}
              orientation={'horizontal'}
            >
              {list.map((label, index) => (
                <Step classes={{ root: classes.stepRoot }} key={label?.text}>
                  <StepLabel
                    classes={{
                      completed: classes.labelRoot,
                      active: classes.labelRoot,
                      label: classes.labelRoot,
                    }}
                    icon={<CustomIcon index={index} activeStep={activeStep} />}
                  >
                    {label?.text}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
