import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import FeeRadioGroup from './feeRadioGroup';
import { TABLET_BREAKPOINT } from '../../../../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFF',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'border': '1px solid #DDDDDD',
      'padding': theme.spacing(2),
      'border-radius': '12px',
    }
  },

  label: {
    fontSize: '14px',
    color: '#061938',
    fontWeight: 400,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '16px',
      'line-height': '100%',
      'color': '#061938',
    }
  },
}));

export default function PaymentMode(props) {
  const {
    value,
    handleOnChange,
    success,
    error,
    onPartialAmountChange,
    partialAmount,
    partialAmountFieldError,
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} textAlign="left">
            {/* <KenRadioGroup */}
            <FeeRadioGroup
              row={false}
              label="Select your payment mode"
              value={value}
              options={[
                { label: 'Full Payment', value: 'Full Payment', subTitle: 'Pay Full Amount at once' },
                { label: 'Partial Payment', value: 'Partial Payment', subTitle: 'Pay Partial' },
              ]}
              condition="Partial Payment"
              onChange={handleOnChange}
              onPartialAmountChange={onPartialAmountChange}
              partialAmount={partialAmount}
              success={success}
              error={error}
              partialAmountFieldError={partialAmountFieldError}
              labelClassName={classes.label}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
