import { axiosInstance } from "../../Utils/apiServices";

export const getAllTask = (applicantid) => {
  const path = `/salesforce?endpoint=/services/apexrest/portal/retrieve/tasks?applicantid=${applicantid}`;

  return axiosInstance
    .request({ url: `${path}`, method: "get" })
    .then((res) => res.data);
};

export const addTask = async (payload) => {
  const path = `/salesforce?endpoint=/services/apexrest/portal/save`;

  return axiosInstance
    .request({
      url: `${path}`,
      method: "post",
      data: {
        tasks: [
          {
            applicantId: payload.id,
            description: payload.description,
            status: "Pending",
            subject: payload.subject,
            type: "Task",
            startDate: payload.start,
            endDate: payload.end,
          },
        ],
      },
    })
    .then((res) => res.data);
};
