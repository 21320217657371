import { Box, InputAdornment, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import KenInput from '../../../../../../../Components/KenInput';
import { TABLET_BREAKPOINT } from '../../../../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFF',

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'border': '1px solid #DDDDDD',
      'border-radius': theme.spacing(1.5),
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }
  },
  remove: {
    color: 'red',
    cursor: 'pointer',
    textDecoration: 'underline',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      textDecoration: 'none',
      'font-weight': '500',
      'font-size': '14px',
      'line-height': '100%',
      marginRight: theme.spacing(1),
    }
  },
  apply: {
    color: '#00218D',
    cursor: 'pointer',
    textDecoration: 'underline',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      textDecoration: 'none',
      'font-weight': '500',
      'font-size': '14px',
      'line-height': '100%',
      'color': '#092682',
      marginRight: theme.spacing(1),
    }
  },
  adornedEnd: {
    // backgroundColor: 'green',
  },
  label: {
    fontSize: '14px',
    color: '#061938',
    fontWeight: 400,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '16px',
      'line-height': '100%',
      'text-transform': 'capitalize',
      'color': '#061938',
      marginBottom: theme.spacing(3),
    }
  },
}));

export default function FeeTextField(props) {
  const {
    value,
    handleCouponCodeChange,
    success,
    handleCouponCodeRemove,
    couponValid,
    handleCouponCodeApply,
    invalidCoupon,
    error,
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <KenInput
              label="Apply Coupon Code"
              placeholder="Enter Coupon Code"
              value={value}
              onChange={handleCouponCodeChange}
              labelClassName={classes.label}
              InputProps={{
                endAdornment: value && (
                  <InputAdornment position="end">
                    <Typography
                      fontSize="14px"
                      className={!couponValid ? classes.apply : classes.remove}
                      onClick={
                        invalidCoupon
                          ? handleCouponCodeRemove
                          : !couponValid
                            ? handleCouponCodeApply
                            : handleCouponCodeRemove
                      }
                    >
                      {invalidCoupon
                        ? 'Retry'
                        : !couponValid
                          ? 'Apply'
                          : 'Remove'}
                    </Typography>
                  </InputAdornment>
                ),
                adornedEnd: classes.adornedEnd,
              }}
              success={success}
              error={error}
              inputProps={{ style: { fontWeight: 500 }, autocomplete: 'off' }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
