import { Box, Grid, Link, Typography } from '@mui/material';
import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import Banner from '../../Assets/Images/academicProgramBannerBg.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import Back from '../../Assets/Svg/back.svg';

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundImage: `url(${Banner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    // minHeight: "211px",
    padding: '24px 116px',
    marginTop: 60,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '40px 5px 20px 5px',
    },
  },
  titleContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'flex',
      width: '100%',
      'justify-content': 'space-between',
      'align-items': 'flex-start',
    },
  },
  programContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      maxWidth: '50%',
    },
  },
  program: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: '0',
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '16px',
      'line-height': '100%',
      display: 'flex',
      'align-items': 'center',
      'white-space': 'normal',
      'text-align': 'right',
    },
    paddingTop: 16,
    paddingBottom: 8,
  },
  details: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'none',
    },
  },
  nav: {
    cursor: 'pointer',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'white-space': 'nowrap',
    },
  },
  white: {
    color: 'white',
  },
  orange: {
    color: '#E75547',
  },
  heading: {
    color: '#FFEBDA',
  },
}));

export default function KenApplicationFormHeader(props) {
  const {
    programName,
    duration,
    batch,
    endDate,
    // academicYear,
    // instituteName,
    // title = "Admission",
  } = props;
  const classes = useStyles();
  //   const { hasBack } = useBack();
  //   const query = new URLSearchParams(useLocation().search);

  const navigate = useNavigate();
  const goToDashboard = () => {
    // if (query.get("new")) {
    // 	hasBack ? navigate(-2) : navigate("/home");
    // } else {
    // 	hasBack ? navigate(-1) : navigate("/home");
    // }
    navigate('/home');
  };
  return (
    <Box className={classes.banner}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} textAlign="left">
          <Box className={classes.titleContainer}>
            <Link alignItems="center" sx={{ textDecoration: 'none' }}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <ArrowBackIcon
                  style={{ color: 'white', height: '16px', width: '16px' }}
                />
                <Typography
                  variant="subtitle1"
                  style={{ color: 'white' }}
                  onClick={() => goToDashboard()}
                  className={classes.nav}
                >
                  Back to Dashboard
                </Typography>
              </Box>
            </Link>
            <Box className={classes.programContainer}>
              <Typography
                variant="h4"
                className={`${classes.white} ${classes.program}`}
                pt={1}
                pb={2}
                noWrap
              >
                {programName}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" className={classes.details}>
              {duration && (
                <Typography variant="subtitle1" className={classes.white}>
                  {duration}
                </Typography>
              )}
              {duration && (
                <Typography
                  variant="subtitle1"
                  className={classes.white}
                  margin="0px 4px"
                >
                  |
                </Typography>
              )}
              <Typography variant="subtitle1" className={classes.white}>
                {batch}
              </Typography>
              {(batch || duration) && (
                <Typography
                  variant="subtitle1"
                  className={classes.white}
                  margin="0px 4px"
                >
                  |
                </Typography>
              )}
              <Typography variant="subtitle1" className={classes.white}>
                Open till: {moment(endDate).format('MMMM DD, YYYY')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={6} md={5} textAlign='right' pt={3}>
					<Typography
						variant='subtitle4'
						className={classes.heading}
						noWrap
						component='div'
					>
						{instituteName}
					</Typography>
					<Typography
						variant='subtitle4'
						className={classes.heading}
						noWrap
						component='div'
					>
						{title}
					</Typography>
					<Typography
						variant='subtitle4'
						className={classes.heading}
						component='div'
					>
						{academicYear}
					</Typography>
				</Grid> */}
      </Grid>
    </Box>
  );
}
