export const defaultJson = [
  {
    stepId: 'Course Details',
    stepName: 'Course Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 0,
    stepType: 'form',
    sections: [
      {
        apiKeyId: 'application',
        sectionId: 'CourseDetails1',
        sectionName: 'Course Details',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionHelp: 'Fields marked with * are required',
        sectionType: 'form',
        fields: [
          {
            fieldId: 'application[studentMentorAssistance]',
            required: true,
            name: 'application[studentMentorAssistance]',
            label:
              'Would you like our student mentor to assist you in the admission process?',
            type: 'select',
            apiKey: 'application[studentMentorAssistance]',
            options: [
              {
                label: 'Yes',
                value: 'Yes',
              },
              {
                label: 'No',
                value: 'No',
              },
            ],
            validationType: 'string',
            validations: {
              required: 'This field is required',
            },
            ui: {
              gridType: 'fullRow',
            },
          },
        ],
      },
      {
        apiKeyId: 'application',
        sectionId: 'ProgramDetails2',
        sectionName: '',
        subSectionName: '',
        showSectionName: false,
        sectionPosition: 2,
        sectionVisible: true,
        sectionHelp: '',
        sectionType: 'form',
        fields: [
          {
            fieldId: 'application[programName]',
            name: 'application[programName]',
            label: 'Program',
            type: 'input',
            options: [],
            disabled: true,
            apiKey: 'Program',
            validationType: 'string',
            validations: [],
            ui: {
              gridType: 'halfRow',
            },
          },
          {
            fieldId: 'application[campusCombinationId]',
            required: true,
            name: 'application[campusCombinationId]',
            label: 'Campus',
            type: 'select',
            options: [],
            apiKey: 'application[campusCombinationId]',
            validations: {
              required: 'This field is required',
            },
            ui: {
              gridType: 'halfRow',
            },
            metaData: {
              optionsKey: 'campuses',
            },
          },
          {
            fieldId: 'application[academicCycle]',
            name: 'application[academicCycle]',
            label: 'Academic Cycle',
            type: 'input',
            apiKey: 'application[academicCycle]',
            validations: [],
            disabled: true,
            ui: {
              gridType: 'halfRow',
            },
          },
          {
            fieldId: 'application[specializationId]',
            name: 'application[specializationId]',
            label: 'Specialization',
            type: 'select',
            apiKey: 'application[specializationId]',
            options: [],
            ui: {
              gridType: 'halfRow',
            },
            metaData: {
              optionsKey: 'specializations',
            },
          },
        ],
      },
    ],
  },
  {
    stepId: 'basic Details',
    stepName: 'Baic Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 1,
    stepType: 'form',
    sections: [
      {
        sectionName: 'Basic Details',
        apiKeyId: 'applicant',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'applicant[firstName]',
            required: true,
            name: 'applicant[firstName]',
            label: 'First Name',
            type: 'input',
            disabled: true,
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'Please Input a valid First Name',
              },
            },
          },
          {
            fieldId: 'applicant[middleName]',
            name: 'applicant[middleName]',
            label: 'Middle Name',
            disabled: true,
            type: 'input',
            validations: {},
          },
          {
            fieldId: 'applicant[lastName]',
            required: true,
            name: 'applicant[lastName]',
            label: 'Last Name',
            disabled: true,
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'Please Input a valid Last Name',
              },
            },
          },
          {
            fieldId: 'applicant[dateOfBirth]',
            required: true,
            name: 'applicant[dateOfBirth]',
            label: 'Date of Birth',
            type: 'date',
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'applicant[nationality]',
            required: true,
            name: 'applicant[nationality]',
            label: 'Nationality',
            type: 'input',
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'applicant[passportNumber]',
            name: 'applicant[passportNumber]',
            label: 'Passport Number',
            type: 'input',
          },
          {
            fieldId: 'applicant[dateOfExpiry]',
            name: 'applicant[dateOfExpiry]',
            label: 'Passport Expiry',
            type: 'date',
          },
          {
            fieldId: 'applicant[phone]',
            required: true,
            name: 'applicant[phone]',
            label: 'Phone Number',
            type: 'phone',
            apiKey: 'applicant[phone]',
            disabled: true,
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'Please Input a valid Phone Number',
              },
            },
          },
          {
            fieldId: 'applicant[email]',
            required: true,
            name: 'applicant[email]',
            label: 'Email',
            disabled: true,
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'Please Input a valid Email',
              },
            },
          },
          {
            fieldId: 'applicant[gender]',
            required: true,
            name: 'applicant[gender]',
            label: 'Gender',
            type: 'radio',
            validations: {
              required: 'This field is required',
            },
            options: [
              {
                label: 'Male',
                value: 'Male',
              },
              {
                label: 'Female',
                value: 'Female',
              },
              {
                label: 'Other',
                value: 'Other',
              },
            ],
          },
          {
            fieldId: 'applicant[martialStatus]',
            required: true,
            name: 'applicant[martialStatus]',
            label: 'Marital Status',
            type: 'radio',
            options: [
              {
                label: 'Single',
                value: 'Single',
              },
              {
                label: 'Married',
                value: 'Married',
              },
              {
                label: 'Other',
                value: 'Others',
              },
            ],
            validations: {
              required: 'This field is required',
            },
          },
        ],
      },
      {
        sectionName: 'Addresses',
        subSectionName: '',
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            name: 'addresses[0]',
            type: 'address',
            options: [
              {
                fieldId: 'mailingCountry',
                required: true,
                name: 'mailingCountry',
                isPartOfCollection: true,
                label: 'Country',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'mailingState',
                required: true,
                name: 'mailingState',
                label: 'State',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
              {
                fieldId: 'mailingDistrict',
                required: true,
                name: 'mailingDistrict',
                label: 'District',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
            ],
          },
          {
            fieldId: 'addresses[0][mailingStreet]',
            required: true,
            name: 'addresses[0][mailingStreet]',
            label: 'Permanent Address',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
            },
          },
          {
            fieldId: 'addresses[0][mailingPostalCode]',
            required: true,
            name: 'addresses[0][mailingPostalCode]',
            label: 'PIN / PO BOX/ ZIP',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 7,
                message: 'This field cannot be more than 7 characters',
              },
              pattern: {
                value: {},
                message: 'Please Input a valid Zipcode',
              },
            },
          },
        ],
      },
    ],
  },
  {
    stepId: 'familyInfo',
    stepName: 'Family Info',
    showStepName: false,
    stepVisible: true,
    stepType: 'form',
    sections: [
      {
        sectionId: 'familyInformation',
        sectionName: 'Family Information',
        subSectionName: '',
        sectionPosition: 2,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'applicantRelations',
            name: 'applicantRelations',
            label: 'Family Information',
            type: 'array',
            defaultItemValue: {
              relationshipType: '',
              firstName: '',
              lastName: '',
              phone: '',
              email: '',
              age: '',
              occupation: '',
              annualIncome: '',
              currencyType: '',
            },
            metaData: {
              addMultiple: true,
              minItems: 1,
            },
            fieldsArray: [
              {
                fieldId: 'relationshipType',
                required: true,
                name: 'relationshipType',
                validations: {
                  required: 'This field is required',
                },
                label: 'Relationship With Applicant',
                type: 'select',
                options: [
                  {
                    label: 'Father',
                    value: 'Father',
                  },
                  {
                    label: 'Mother',
                    value: 'Mother',
                  },
                  {
                    label: 'Others',
                    value: 'Others',
                  },
                ],
              },
              {
                fieldId: 'firstName',
                required: true,
                name: 'firstName',
                label: 'First Name',
                type: 'input',
                validations: {
                  required: 'This field is required',
                  pattern: {
                    value: {},
                    message: 'Please Input a valid First Name',
                  },
                },
              },
              {
                fieldId: 'lastName',
                required: true,
                name: 'lastName',
                label: 'Last Name',
                type: 'input',
                validations: {
                  required: 'This field is required',
                  pattern: {
                    value: {},
                    message: 'Please Input a valid Last Name',
                  },
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'phone',
                name: 'phone',
                label: 'Mobile',
                required: true,
                type: 'phone',
                validations: {
                  required: 'This field is required',
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'email',
                name: 'email',
                label: 'Email',
                type: 'input',
                required: true,
                validations: {
                  required: 'This field is required',
                  pattern: {
                    value: {},
                    message: 'Please Input a valid Email',
                  },
                },
              },
              {
                fieldId: 'age',
                required: true,
                name: 'age',
                label: 'Age',
                type: 'input',
                validations: {
                  required: 'This field is required',
                  pattern: {
                    value: {},
                    message: 'Please Input a valid Age',
                  },
                },
              },
              {
                fieldId: 'occupation',
                required: true,
                name: 'occupation',
                label: 'Occupation',
                type: 'input',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'annualIncome',
                name: 'annualIncome',
                label: 'Annual Income',
                type: 'input',
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'currencyType',
                name: 'currencyType',
                label: 'Currency Type',
                type: 'select',
                pattern: {
                  value: {},
                  message: 'Please Input a valid Income',
                },
                options: [
                  {
                    label: 'Australian Dollar (AUD)',
                    value: 'Australian Dollar (AUD)',
                  },
                  {
                    label: 'British Pound (GBP)',
                    value: 'British Pound (GBP)',
                  },
                  {
                    label: 'Canadian Dollar (CAD)',
                    value: 'Canadian Dollar (CAD)',
                  },
                  {
                    label: 'European Euro (EUR)',
                    value: 'European Euro (EUR)',
                  },
                  {
                    label: 'Indian Rupee (INR)',
                    value: 'Indian Rupee (INR)',
                  },
                  {
                    label: 'New Zealand Dollar (NZD)',
                    value: 'New Zealand Dollar (NZD)',
                  },
                  {
                    label: 'Singapore dollar (SGD)',
                    value: 'Singapore dollar (SGD)',
                  },
                  {
                    label: 'U.S. Dollar (USD)',
                    value: 'U.S. Dollar (USD)',
                  },
                  {
                    label: 'UAE Dirham (AED)',
                    value: 'UAE Dirham (AED)',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    stepId: 'basic Details',
    stepName: 'Employment',
    showStepName: false,
    stepVisible: true,
    stepPosition: 1,
    stepType: 'form',
    sections: [
      {
        sectionId: 'employmentDetails',
        sectionName: 'Employment Details',
        subSectionName: '',
        sectionPosition: 3,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'application[doYouHaveWorkExperience]',
            name: 'application[doYouHaveWorkExperience]',
            required: true,
            label: 'Do you have any work experience or articleship',
            type: 'radio',
            options: [
              {
                label: 'Yes',
                value: 'true',
              },
              {
                label: 'No',
                value: 'false',
              },
            ],
            validations: [],
            ui: {
              gridType: 'fullRow',
            },
          },
          {
            fieldId: 'workExperiences',
            name: 'workExperiences',
            label: 'Work Experiences',
            type: 'array',
            metaData: {
              addMultiple: true,
              minItems: 1,
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'application[doYouHaveWorkExperience]',
                expectedValue: 'true',
                type: 'LEAF',
              },
            },
            fieldsArray: [
              {
                fieldId: 'jobType',
                name: 'jobType',
                label: 'Job Type',
                type: 'select',
                options: [
                  {
                    label: 'Full Time',
                    value: 'Full Time',
                  },
                  {
                    label: 'Part Time',
                    value: 'Part Time',
                  },
                  {
                    label: 'Internship',
                    value: 'Internship',
                  },
                  {
                    label: 'Articleship',
                    value: 'Articleship',
                  },
                  {
                    label: 'Full Time (Still Working)',
                    value: 'Full Time (Still Working)',
                  },
                ],
              },
              {
                fieldId: 'organisationName',
                name: 'organisationName',
                label: 'Company',
                type: 'input',
                conditional: true,
              },
              {
                fieldId: 'industryType',
                name: 'industryType',
                label: 'Industry Type',
                type: 'input',
              },
              {
                fieldId: 'location',
                name: 'location',
                label: 'Location',
                type: 'input',
              },
              {
                fieldId: 'designation',
                name: 'designation',
                label: 'Roles /KRA',
                type: 'input',
              },
              {
                fieldId: 'dateOfJoining',
                name: 'dateOfJoining',
                label: 'Date Of Joining',
                type: 'date',
                validations: [],
              },
              {
                fieldId: 'dateOfLeaving',
                name: 'dateOfLeaving',
                label: 'Date Of Leaving',
                type: 'date',
              },
              {
                fieldId: 'noOfMonths',
                name: 'noOfMonths',
                label: 'Experience in Months',
                type: 'input',
              },
              {
                fieldId: 'currencyType',
                name: 'currencyType',
                label: 'Currency Type',
                type: 'select',
                options: [
                  {
                    label: 'Australian Dollar (AUD)',
                    value: 'Australian Dollar (AUD)',
                  },
                  {
                    label: 'British Pound (GBP)',
                    value: 'British Pound (GBP)',
                  },
                  {
                    label: 'Canadian Dollar (CAD)',
                    value: 'Canadian Dollar (CAD)',
                  },
                  {
                    label: 'European Euro (EUR)',
                    value: 'European Euro (EUR)',
                  },
                  {
                    label: 'Indian Rupee (INR)',
                    value: 'Indian Rupee (INR)',
                  },
                  {
                    label: 'New Zealand Dollar (NZD)',
                    value: 'New Zealand Dollar (NZD)',
                  },
                  {
                    label: 'Singapore dollar (SGD)',
                    value: 'Singapore dollar (SGD)',
                  },
                  {
                    label: 'U.S. Dollar (USD)',
                    value: 'U.S. Dollar (USD)',
                  },
                  {
                    label: 'UAE Dirham (AED)',
                    value: 'UAE Dirham (AED)',
                  },
                ],
              },
              {
                fieldId: 'annualSalary',
                name: 'annualSalary',
                label: 'Annual Salary',
                type: 'input',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    stepId: 'basic Details',
    stepName: 'Education',
    showStepName: false,
    stepVisible: true,
    stepPosition: 1,
    stepType: 'form',
    sections: [
      {
        apiKeyId: '',
        sectionName: 'Education Details',
        sectionId: 'educationHistories[0]',
        subSectionName: 'Post Graduation',
        sectionPosition: 1,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'educationHistories[0][yearOfPassing]',
            name: 'educationHistories[0][yearOfPassing]',
            label: 'Year of completion',
            type: 'date',
            validations: [],
          },
          {
            fieldId: 'educationHistories[0][institutionName]',
            name: 'educationHistories[0][institutionName]',
            label: 'University/Board',
            type: 'input',
            validations: [],
          },
          {
            fieldId: 'educationHistories[0][obtainedMarks]',
            required: true,
            name: 'educationHistories[0][obtainedMarks]',
            label: 'Percentage/CGPA',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This field is should be a number',
              },
            },
          },
          {
            fieldId: 'educationHistories[0][educationType]',
            name: 'educationHistories[0][educationType]',
            label: 'Education Type',
            type: 'select',
            options: [
              {
                label: 'Full time',
                value: 'Full time',
              },
              {
                label: 'Part time',
                value: 'Part time',
              },
              {
                label: 'Distance',
                value: 'Distance',
              },
            ],
            validations: [],
          },
          {
            fieldId: 'educationHistories[0][degreeEarned]',
            name: 'educationHistories[0][degreeEarned]',
            label: 'Degree',
            type: 'select',
            options: [
              {
                label: 'AA',
                value: 'AA',
              },
              {
                label: 'AS',
                value: 'AS',
              },
              {
                label: 'BA',
                value: 'BA',
              },
              {
                label: 'BS',
                value: 'BS',
              },
              {
                label: 'GED',
                value: 'GED',
              },
              {
                label: 'High School Diploma',
                value: 'High School Diploma',
              },
              {
                label: 'JD',
                value: 'JD',
              },
              {
                label: 'MA',
                value: 'MA',
              },
              {
                label: 'MBA',
                value: 'MBA',
              },
              {
                label: 'MD',
                value: 'MD',
              },
              {
                label: 'MS',
                value: 'MS',
              },
              {
                label: 'PhD',
                value: 'PhD',
              },
              {
                label: 'Other',
                value: 'Other',
              },
            ],
          },
          {
            name: 'educationHistories[0]',
            type: 'address',
            options: [
              {
                fieldId: 'country',
                required: true,
                name: 'country',
                isPartOfCollection: true,
                label: 'Country',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'state',
                required: true,
                name: 'state',
                label: 'State',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
              {
                fieldId: 'city',
                required: true,
                name: 'city',
                label: 'District',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
            ],
          },
        ],
      },
      {
        apiKeyId: '',
        sectionName: '',
        sectionId: 'educationHistories[1]',
        subSectionName: 'Graduation',
        sectionPosition: 1,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'educationHistories[1][yearOfPassing]',
            name: 'educationHistories[1][yearOfPassing]',
            label: 'Year of completion',
            type: 'date',
            validations: [],
          },
          {
            fieldId: 'educationHistories[1][institutionName]',
            name: 'educationHistories[1][institutionName]',
            label: 'University/Board',
            type: 'input',
            validations: [],
          },
          {
            fieldId: 'educationHistories[1][obtainedMarks]',
            required: true,
            name: 'educationHistories[1][obtainedMarks]',
            label: 'Percentage/CGPA',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This field is should be a number',
              },
            },
          },
          {
            fieldId: 'educationHistories[1][educationType]',
            name: 'educationHistories[1][educationType]',
            label: 'Education Type',
            type: 'select',
            options: [
              {
                label: 'Full time',
                value: 'Full time',
              },
              {
                label: 'Part time',
                value: 'Part time',
              },
              {
                label: 'Distance',
                value: 'Distance',
              },
            ],
            validations: [],
          },
          {
            fieldId: 'educationHistories[1][degreeEarned]',
            name: 'educationHistories[1][degreeEarned]',
            label: 'Degree',
            type: 'select',
            options: [
              {
                label: 'AA',
                value: 'AA',
              },
              {
                label: 'AS',
                value: 'AS',
              },
              {
                label: 'BA',
                value: 'BA',
              },
              {
                label: 'BS',
                value: 'BS',
              },
              {
                label: 'GED',
                value: 'GED',
              },
              {
                label: 'High School Diploma',
                value: 'High School Diploma',
              },
              {
                label: 'JD',
                value: 'JD',
              },
              {
                label: 'MA',
                value: 'MA',
              },
              {
                label: 'MBA',
                value: 'MBA',
              },
              {
                label: 'MD',
                value: 'MD',
              },
              {
                label: 'MS',
                value: 'MS',
              },
              {
                label: 'PhD',
                value: 'PhD',
              },
              {
                label: 'Other',
                value: 'Other',
              },
            ],
          },
          {
            name: 'educationHistories[1]',
            type: 'address',
            options: [
              {
                fieldId: 'country',
                required: true,
                name: 'country',
                isPartOfCollection: true,
                label: 'Country',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'state',
                required: true,
                name: 'state',
                label: 'State',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
              {
                fieldId: 'city',
                required: true,
                name: 'city',
                label: 'District',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
            ],
          },
        ],
      },
      {
        sectionId: 'semester',
        sectionName: '',
        subSectionName: 'Semester/Year-Wise Graduation Marks Details',
        sectionPosition: 3,
        sectionVisible: true,
        mergePrevMobile: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'educationHistories[1][semesteryearwiseMarks]',
            required: true,
            name: 'educationHistories[1][semesteryearwiseMarks]',
            label: 'Type',
            type: 'select',
            options: [
              { label: 'Semester', value: 'Semester' },
              { label: 'Year', value: 'Year' },
            ],
            validations: [],
            ui: {
              gridType: 'fullRow',
            },
          },

          {
            fieldId: `educationHistories[1][semesterwiseMarks]`,
            name: `educationHistories[1][semesterwiseMarks]`,
            label: 'Semester wise marks',
            type: 'array',
            metaData: {
              addMultiple: true,
              minItems: 1,
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'educationHistories[1][semesteryearwiseMarks]',
                expectedValue: 'Semester',
                type: 'LEAF',
              },
            },
            fieldsArray: [
              {
                fieldId: 'semesterNumber',
                required: true,
                name: 'semesterNumber',
                label: 'Select semester',
                type: 'select',
                options: [
                  { label: 'Semester 1', value: 'semester1Marks' },
                  { label: 'Semester 2', value: 'semester2Marks' },
                  { label: 'Semester 3', value: 'semester3Marks' },
                  { label: 'Semester 4', value: 'semester4Marks' },
                  { label: 'Semester 5', value: 'semester5Marks' },
                  { label: 'Semester 6', value: 'semester6Marks' },
                  { label: 'Semester 7', value: 'semester7Marks' },
                  { label: 'Semester 8', value: 'semester8Marks' },
                ],
                validations: [],
              },
              {
                fieldId: 'semesterMarks',
                //required: true,
                name: 'semesterMarks',
                label: 'Total Marks',
                type: 'input',
                validations: [],
              },
            ],
          },
          {
            fieldId: `educationHistories[1][yearwiseMarks]`,
            name: `educationHistories[1][yearwiseMarks]`,
            //required: true,
            label: 'Year wise marks',
            type: 'array',
            metaData: {
              addMultiple: true,
              minItems: 1,
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'educationHistories[1][semesteryearwiseMarks]',
                expectedValue: 'Year',
                type: 'LEAF',
              },
            },
            fieldsArray: [
              {
                fieldId: 'yearNumber',
                required: true,
                name: 'yearNumber',
                label: 'Select Year',
                type: 'select',
                options: [
                  { label: 'Year 1', value: 'year1' },
                  { label: 'Year 2', value: 'year2' },
                  { label: 'Year 3', value: 'year3' },
                  { label: 'Year 4', value: 'year4' },
                ],
                validations: [],
              },
              {
                fieldId: 'yearMarks',
                //required: true,
                name: 'yearMarks',
                label: 'Total Marks',
                type: 'input',
                validations: [],
              },
            ],
          },
        ],
      },
      {
        sectionId: 'educationHistories[2]',
        sectionName: '',
        subSectionName: 'Grade 12/Secondary School',
        sectionPosition: 5,
        sectionVisible: true,
        mergePrevMobile: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'educationHistories[2][educationType]',
            name: 'educationHistories[2][educationType]',
            required: true,
            label: 'Education Type',
            type: 'select',
            options: [
              {
                label: 'Full time',
                value: 'Full time',
              },
              {
                label: 'Part time',
                value: 'Part time',
              },
              {
                label: 'Distance',
                value: 'Distance',
              },
            ],
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'educationHistories[2][degreeCGPADGPA]',
            name: 'educationHistories[2][degreeCGPADGPA]',
            required: true,
            label: 'Grade Type',
            type: 'select',
            options: [
              {
                label: 'CGPA',
                value: 'CGPA',
              },
              {
                label: 'GPA',
                value: 'GPA',
              },
              {
                label: 'Grade',
                value: 'Grade',
              },
              {
                label: 'Percentage',
                value: 'Percentage',
              },
            ],
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'educationHistories[2][obtainedMarks]',
            required: true,
            name: 'educationHistories[2][obtainedMarks]',
            label: 'Final percentage',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This is not a valid value',
              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'educationHistories[2][degreeCGPADGPA]',
                expectedValue: 'Percentage',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'educationHistories[2][obtainedMarks]',
            required: true,
            name: 'educationHistories[2][obtainedMarks]',
            label: 'Final CGPA',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This is not a valid value',
              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'educationHistories[2][degreeCGPADGPA]',
                expectedValue: 'CGPA',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'educationHistories[2][obtainedMarks]',
            required: true,
            name: 'educationHistories[2][obtainedMarks]',
            label: 'Final GPA',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This is not a valid value',
              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'educationHistories[2][degreeCGPADGPA]',
                expectedValue: 'GPA',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'educationHistories[2][obtainedMarks]',
            required: true,
            name: 'educationHistories[2][obtainedMarks]',
            label: 'Final grade',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This is not a valid value',
              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'educationHistories[2][degreeCGPADGPA]',
                expectedValue: 'Grade',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'educationHistories[2][yearOfPassing]',
            required: true,
            name: 'educationHistories[2][yearOfPassing]',
            label: 'Completion year',
            type: 'date',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'educationHistories[2][placeOfStudy]',
            required: true,
            name: 'educationHistories[2][placeOfStudy]',
            label: 'School Name',
            type: 'input',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            name: 'educationHistories[2]',
            type: 'address',
            options: [
              {
                fieldId: 'country',
                required: true,
                name: 'country',
                isPartOfCollection: true,
                label: 'Country',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'state',
                required: true,
                name: 'state',
                label: 'State',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
              {
                fieldId: 'city',
                required: true,
                name: 'city',
                label: 'City',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
            ],
          },
        ],
      },
      {
        sectionId: 'educationHistories[3]',
        sectionName: '',
        subSectionName: 'Grade 10/High School',
        sectionPosition: 5,
        sectionVisible: true,
        mergePrevMobile: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'educationHistories[3][educationType]',
            name: 'educationHistories[3][educationType]',
            required: true,
            label: 'Education Type',
            type: 'select',
            options: [
              {
                label: 'Full time',
                value: 'Full time',
              },
              {
                label: 'Part time',
                value: 'Part time',
              },
              {
                label: 'Distance',
                value: 'Distance',
              },
            ],
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'educationHistories[3][degreeCGPADGPA]',
            name: 'educationHistories[3][degreeCGPADGPA]',
            required: true,
            label: 'Grade Type',
            type: 'select',
            options: [
              {
                label: 'CGPA',
                value: 'CGPA',
              },
              {
                label: 'GPA',
                value: 'GPA',
              },
              {
                label: 'Grade',
                value: 'Grade',
              },
              {
                label: 'Percentage',
                value: 'Percentage',
              },
            ],
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'educationHistories[3][obtainedMarks]',
            required: true,
            name: 'educationHistories[3][obtainedMarks]',
            label: 'Final percentage',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This is not a valid value',
              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'educationHistories[3][degreeCGPADGPA]',
                expectedValue: 'Percentage',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'educationHistories[3][obtainedMarks]',
            required: true,
            name: 'educationHistories[3][obtainedMarks]',
            label: 'Final CGPA',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This is not a valid value',
              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'educationHistories[3][degreeCGPADGPA]',
                expectedValue: 'CGPA',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'educationHistories[3][obtainedMarks]',
            required: true,
            name: 'educationHistories[3][obtainedMarks]',
            label: 'Final GPA',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This is not a valid value',
              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'educationHistories[3][degreeCGPADGPA]',
                expectedValue: 'GPA',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'educationHistories[3][obtainedMarks]',
            required: true,
            name: 'educationHistories[3][obtainedMarks]',
            label: 'Final grade',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'This is not a valid value',
              },
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'educationHistories[3][degreeCGPADGPA]',
                expectedValue: 'Grade',
                type: 'LEAF',
              },
            },
          },
          {
            fieldId: 'educationHistories[3][yearOfPassing]',
            required: true,
            name: 'educationHistories[3][yearOfPassing]',
            label: 'Completion year',
            type: 'date',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'educationHistories[3][placeOfStudy]',
            required: true,
            name: 'educationHistories[3][placeOfStudy]',
            label: 'School Name',
            type: 'input',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            name: 'educationHistories[3]',
            type: 'address',
            options: [
              {
                fieldId: 'country',
                required: true,
                name: 'country',
                isPartOfCollection: true,
                label: 'Country',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
              },
              {
                fieldId: 'state',
                required: true,
                name: 'state',
                label: 'State',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
              {
                fieldId: 'city',
                required: true,
                name: 'city',
                label: 'City',
                type: 'select',
                validations: {
                  required: 'This field is required',
                },
                options: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    stepId: 'basic Details',
    stepName: 'Other',
    showStepName: false,
    stepVisible: true,
    stepPosition: 1,
    stepType: 'form',
    sections: [
      {
        sectionId: 'otherInfo',
        sectionName: 'Other Information',
        subSectionName: '',
        sectionPosition: 6,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'application[sportsAchievements]',
            name: 'application[sportsAchievements]',
            label: 'Sport Achievement',
            type: 'input',
          },
          {
            fieldId: 'application[professionalAchievements]',
            name: 'application[professionalAchievements]',
            label: 'Professional Achievement',
            type: 'input',
          },
          {
            fieldId: 'application[extraCurricularActivities]',
            name: 'application[extraCurricularActivities]',
            label:
              'Extra Curricular Activities (social, political, personal etc)',
            type: 'input',
          },
          {
            fieldId: 'application[exceptionalAcademicAchievements]',
            name: 'application[exceptionalAcademicAchievements]',
            label: 'Exceptional Academic Achievements',
            type: 'input',
          },
          {
            fieldId: 'application[howDoYouPlanToFundYourEducation]',
            required: true,
            name: 'application[howDoYouPlanToFundYourEducation]',
            label: 'How do you plan to fund your education?',
            type: 'select',
            options: [
              {
                label: 'Self-funding',
                value: 'Self-funding',
              },
              {
                label: 'Bank Loan Only',
                value: 'Bank Loan Only',
              },
              {
                label: 'Self-funding and Scholarship',
                value: 'Self-funding and Scholarship',
              },
              {
                label: 'Bank Loan and Scholarship',
                value: 'Bank Loan and Scholarship',
              },
              {
                label: 'Self-funding + Bank Loan',
                value: 'Self-funding + Bank Loan',
              },
              {
                label: 'Others',
                value: 'Others',
              },
            ],
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'application[fundDetails]',
            required: true,
            name: 'application[fundDetails]',
            label: 'Please Specify',
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'application[howDoYouPlanToFundYourEducation]',
                expectedValue: 'Others',
                type: 'LEAF',
              },
            },
            type: 'input',
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'applicant[isDifferentlyAbled]',
            required: true,
            name: 'applicant[isDifferentlyAbled]',
            label:
              'Are you physically challenged or suffering from permanent disability?',
            type: 'radio',
            options: [
              {
                label: 'Yes',
                value: 'Yes',
              },
              {
                label: 'No',
                value: 'No',
              },
            ],
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'specialAbilityDetails',
            required: true,
            conditional: true,
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'applicant[isDifferentlyAbled]',
                expectedValue: 'Yes',
                type: 'LEAF',
              },
            },
            name: 'specialAbilityDetails',
            label: 'Please share details',
            type: 'input',
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'application[statementOfPurpose]',
            required: true,
            name: 'application[statementOfPurpose]',
            label: 'Statement of purpose',
            type: 'textArea',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 800,
                message: 'This field cannot be more than 800 characters',
              },
            },
          },
          {
            fieldId: 'application[mastersPlan]',
            required: false,
            name: 'application[mastersPlan]',
            label:
              'What do you plan to do after finishing a master’s degree at S P Jain?',
            type: 'textArea',
            validations: {
              maxLength: {
                value: 800,
                message: 'This field cannot be more than 800 characters',
              },
            },
            options: [],
          },
          {
            fieldId: 'application[companySponsorship]',
            required: false,
            name: 'application[companySponsorship]',
            label: 'Are you sponsored by your company?',
            type: 'radio',
            options: [
              {
                label: 'Yes',
                value: 'Yes',
              },
              {
                label: 'No',
                value: 'No',
              },
            ],
          },
        ],
      },
      {
        apiKeyId: 'languages',
        sectionId: 'languageInfo',
        sectionName: 'Language Proficiency Details',
        subSectionName: '',
        sectionPosition: 7,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'application[languageSpokenAtHome]',
            required: true,
            name: 'application[languageSpokenAtHome]',
            label: 'Mother Tongue',
            type: 'select',
            options: [
              {
                label: 'Assamese',
                value: 'Assamese',
              },
              {
                label: 'Bengali',
                value: 'Bengali',
              },
              {
                label: 'English',
                value: 'English',
              },
              {
                label: 'Gujarati',
                value: 'Gujarati',
              },
              {
                label: 'Hindi',
                value: 'Hindi',
              },
              {
                label: 'Kannada',
                value: 'Kannada',
              },
              {
                label: 'Malayalam',
                value: 'Malayalam',
              },
              {
                label: 'Marathi',
                value: 'Marathi',
              },
              {
                label: 'Odia',
                value: 'Odia',
              },
              {
                label: 'Punjabi',
                value: 'Punjabi',
              },
              {
                label: 'Tamil',
                value: 'Tamil',
              },
              {
                label: 'Telugu',
                value: 'Telugu',
              },
              {
                label: 'Urdu',
                value: 'Urdu',
              },
              {
                label: 'Other',
                value: 'Other',
              },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
                sm: 12,
              },
            },
          },
          {
            fieldId: 'languages',
            name: 'languages',
            label: 'Known Languages',
            type: 'array',
            fieldsArray: [
              {
                fieldId: 'languageName',
                name: 'languageName',
                required: true,
                label: 'Language Name',
                type: 'input',
                validations: {
                  required: 'This field is required',
                  pattern: {
                    value: {},
                    message: 'Please enter a valid language',
                  },
                },
              },
              {
                fieldId: 'proficiency',
                required: true,
                name: 'proficiency',
                label: 'Proficiency',
                type: 'select',
                options: [
                  {
                    label: 'Intermediate',
                    value: 'Intermediate',
                  },
                  {
                    label: 'Advanced',
                    value: 'Advanced',
                  },
                  {
                    label: 'Superior',
                    value: 'Superior',
                  },
                ],
                validations: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
      {
        apiKeyId: 'application',
        sectionId: 'references',
        sectionName: 'References',
        subSectionName: '',
        sectionPosition: 8,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'application[appliedFromAnywhere]',
            name: 'application[appliedFromAnywhere]',
            required: true,
            label:
              'Have you applied to S P Jain-Dubai/Mumbai/Singapore/Sydney in the past?',
            type: 'radio',
            options: [
              {
                label: 'Yes',
                value: 'Yes',
              },
              {
                label: 'No',
                value: 'No',
              },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'application[pastBatch]',
            required: true,
            name: 'application[pastBatch]',
            label: 'Batch',
            type: 'input',
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'application[appliedFromAnywhere]',
                expectedValue: 'Yes',
                type: 'LEAF',
              },
            },
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'application[educationalConsultantReferral]',
            required: true,
            name: 'application[educationalConsultantReferral]',
            label: 'Were you referred by Education Consultant of SP Jain?',
            type: 'radio',
            options: [
              {
                label: 'Yes',
                value: 'Yes',
              },
              {
                label: 'No',
                value: 'No',
              },
            ],
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'application[consultantName]',
            required: true,
            name: 'application[consultantName]',
            label: 'Consultant Name',
            type: 'input',
            conditional: true,
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'application[educationalConsultantReferral]',
                expectedValue: 'Yes',
                type: 'LEAF',
              },
            },
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'application[howDidYouHearAboutSPJain]',
            required: true,
            name: 'application[howDidYouHearAboutSPJain]',
            label: 'How did you hear about SP Jain?',
            type: 'select',
            options: [
              {
                label: 'Alumnus of SP Jain',
                value: 'Alumnus of SP Jain',
              },
              {
                label: 'EDM Invite',
                value: 'EDM Invite',
              },
              {
                label: 'Email Promotion',
                value: 'Email Promotion',
              },
              {
                label: 'Info Session',
                value: 'Info Session',
              },
              {
                label: 'Newpaper / Magazine Article or Advertisement',
                value: 'Newpaper / Magazine Article or Advertisement',
              },
              {
                label: 'SP Jain Representative',
                value: 'SP Jain Representative',
              },
              {
                label: 'SP Jain Website',
                value: 'SP Jain Website',
              },
              {
                label: 'Social Media',
                value: 'Social Media',
              },
              {
                label: 'TV / Radio',
                value: 'TV / Radio',
              },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'application[wereYouReferredByAlumniReferral]',
            required: true,
            name: 'application[wereYouReferredByAlumniReferral]',
            label: 'Were you referred by Alumnus or Active student',
            apiKey: 'application[wereYouReferredByAlumniReferral]',
            type: 'radio',
            options: [
              {
                label: 'Yes',
                value: 'Yes',
              },
              {
                label: 'No',
                value: 'No',
              },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'application[alumniStudent]',
            required: true,
            name: 'application[alumniStudent]',
            label: 'Alumni Student',
            type: 'input',
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'application[wereYouReferredByAlumniReferral]',
                expectedValue: 'Yes',
                type: 'LEAF',
              },
            },
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'application[alumniEmail]',
            required: true,
            name: 'application[alumniEmail]',
            label: 'Email',
            type: 'input',
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'application[wereYouReferredByAlumniReferral]',
                expectedValue: 'Yes',
                type: 'LEAF',
              },
            },
            validations: {
              required: 'This field is required',
              pattern: {
                value: {},
                message: 'Please Input a valid Email',
              },
            },
          },
          {
            fieldId: 'application[alumniMobile]',
            required: true,
            name: 'application[alumniMobile]',
            label: 'Mobile',
            type: 'phone',
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'application[wereYouReferredByAlumniReferral]',
                expectedValue: 'Yes',
                type: 'LEAF',
              },
            },
            validations: {
              required: 'This field is required',
            },
          },
          {
            fieldId: 'application[alumniYear]',
            required: true,
            name: 'application[alumniYear]',
            label: 'Batch',
            type: 'input',
            rule: {
              effect: 'SHOW',
              condition: {
                scope: 'application[wereYouReferredByAlumniReferral]',
                expectedValue: 'Yes',
                type: 'LEAF',
              },
            },
            validations: {
              required: 'This field is required',
            },
          },
        ],
      },
    ],
  },
  {
    stepId: 'summaryWithDeclaration',
    stepName: 'Summary and Declaration',
    stepPosition: 5,
    showStepName: true,
    stepVisible: true,
    stepType: 'summary',
    voucher: true,
    sections: [],
  },
];
