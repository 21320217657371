import React from 'react';
import KenGrid from '../../../../Components/KenGrid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import moment from 'moment';
import { getFeeReceipt } from '../../../../Utils/apiServices';
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
  subHeadingFeeType: {
    color: theme.palette.KenColors.grey3,
    fontSize: 14,
    fontWeight: 500,
  },
  subHeadingModeSuccess: {
    fontStyle: 'italic',
    color: theme.palette.KenColors.tertiaryGreen504,
    fontSize: 12,
  },
  subHeadingModeFail: {
    fontStyle: 'italic',
    color: theme.palette.KenColors.statusRed,
    fontSize: 12,
  },
  typoPaymentTypePartialSuccess: {
    fontSize: 12,
    color: theme.palette.KenColors.kenWhite,
    background: theme.palette.KenColors.statusGreen,
    borderRadius: 4,
  },
  typoPaymentTypePartialFailed: {
    fontSize: 12,
    color: theme.palette.KenColors.kenWhite,
    background: theme.palette.KenColors.statusRed,
    borderRadius: 4,
  },
  typoPaymentTypeFullSuccess: {
    fontSize: 12,
    color: theme.palette.KenColors.kenWhite,
    background: theme.palette.KenColors.statusGreen,
    borderRadius: 4,
  },
  typoPaymentTypeFullFailed: {
    fontSize: 12,
    color: theme.palette.KenColors.kenWhite,
    background: theme.palette.KenColors.statusRed,
    borderRadius: 4,
  },
  subHeadingPaymentTypeSuccess: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.KenColors.grey3,
  },
  statusBadge: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      maxHeight: '18px',
      display: 'flex',
      'flex-direction': 'row',
      'justify-content': 'center',
      'align-items': 'center',
      padding: '8px',
      gap: '10px',
      background: '#F6C445',
      'border-radius': '4px',
      width: 'fit-content',

      'font-style': 'normal;',
      'font-weight': '500;',
      'font-size': '12px;',
      'line-height': '100%;',
      'text-transform': 'capitalize;',
      color: '#FFFFFF;',
    },
  },
  rootContainer: {
    border: `1px solid ${theme.palette.KenColors.grey3}`,
    borderRadius: 8,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      borderRadius: 0,
      border: 0,
    },
  },
  accordionDetail: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: 0,
    },
  },
  card: {
    background: '#F7F8FB',
    'border-radius': '12px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  summary: {
    paddingTop: 0,
    '& > .MuiAccordionSummary-content': {
      marginTop: `${theme.spacing(1.5)} 0px !important!important`,
    },
  },
  date: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '10px',
    'line-height': '100%',
    'text-align': 'right',
    'text-transform': 'capitalize',
    color: theme.palette.KenColors.grey2,
  },
  subItemTitle: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '100%',
    color: theme.palette.KenColors.grey3,
  },
  transactionIdValue: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '100%',
    color: theme.palette.KenColors.grey2,
    marginTop: theme.spacing(0.5),
  },
  transactionIdLabel: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '10px',
    'line-height': '100%',
    color: theme.palette.KenColors.grey3,
  },
  download: {
    fontWeight: 500,
    color: theme.palette.KenColors.vibrantBlue,
    textDecorationLine: 'underline',
    marginRight: 16,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: 0,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

// const data = [
//   {
//     feeType: 'Aptitude Fee',
//     feeAmount: '₹ 200',
//     transactionId: '164738456742',
//     date: '04 Jul 2022',
//     mode: 'UPI Paytm',
//     paymentType: 'Partial',
//     balance: 0,
//     courseName: 'B.Tech,Computer Sc.',
//     time: '12:01 PM',
//     paidStatus: 'Successful',
//     subRows: [
//       {
//         feeType: 'Aptitude Fee',
//         feeAmount: '₹ 100',
//         transactionId: '164738456742',
//         date: '04 Jul 2022',
//         mode: 'UPI Paytm',
//         paymentType: 'Partial',
//         paidStatus: 'Successful',
//       },
//       {
//         feeType: 'Aptitude Fee',
//         feeAmount: '₹ 100',
//         transactionId: '164738456742',
//         date: '04 Jul 2022',
//         mode: 'UPI Paytm',
//         paidStatus: 'Successful',
//         paymentType: 'Partial',
//       },
//     ],
//   },
//   {
//     feeType: 'Application Fee',
//     feeAmount: '₹ 200',
//     transactionId: '164738456742',
//     date: '04 Jul 2022',
//     mode: 'Credit Card',
//     paymentType: 'Full',
//     courseName: 'B.Tech,Computer Sc.',
//     time: '12:01 PM',
//     paidStatus: 'Successful',
//     subRows: [],
//   },
// ];

const RenderTransactionCards = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  console.log(data);
  return (
    <Grid container xs={12}>
      {data.map((item) => (
        <Grid
          item
          container
          p={theme.spacing(2)}
          flexDirection="column"
          className={classes.card}
        >
          <Grid item container justifyContent="space-between">
            <Grid xs={6}>
              <Typography variant="h5" fontWeight={500} mb={theme.spacing(1)}>
                {item.feeType}
              </Typography>
            </Grid>
            <Grid xs={6} textAlign="right">
              <Typography
                className={
                  item?.paidStatus === 'Successful'
                    ? classes.subHeadingModeSuccess
                    : classes.subHeadingModeFail
                }
              >
                {item?.paidStatus}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            mb={theme.spacing(3)}
          >
            <Grid xs={6}>
              <Typography
                variant="h5"
                fontWeight={500}
                className={classes.subHeadingFeeType}
              >
                {item?.courseName}
              </Typography>
            </Grid>
            <Grid xs={6} textAlign="right">
              <Typography variant="h5" fontWeight={500}>
                {item.feeAmount}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid xs={6} container alignItems="center">
              <QueryBuilderIcon style={{ height: '16px', color: '#092682' }} />
              <Typography
                variant="h5"
                fontWeight={500}
                className={classes.date}
              >
                {item.date} {item?.time}
              </Typography>
            </Grid>
            <Grid xs={6} textAlign="right">
              {item?.paymentType === 'Partial' && (
                <Typography className={classes.subHeadingPaymentTypeSuccess}>
                  {item?.balance === 0 ? 'Completed' : 'Incomplete'}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: theme.spacing(2), width: '100%' }} />
          <Grid item>
            <Accordion
              square
              sx={{
                border: 0,
                'box-shadow': 'none',
              }}
            >
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon style={{ color: '#000000' }} />}
                sx={{ padding: 0, background: '#F7F8FB' }}
              >
                <Grid item container justifyContent="space-between">
                  <Grid xs={6} item container flexDirection="column">
                    <Grid item className={classes.transactionIdLabel}>
                      Transaction Id
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        fontWeight={500}
                        className={classes.transactionIdValue}
                      >
                        {item.transactionId}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={6}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    item
                  >
                    <Typography className={classes.statusBadge}>
                      Partial
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetail}>
                {(item?.subRows || []).map((subItem) => (
                  <>
                    <Divider />
                    <Grid container p={theme.spacing(1.5)}>
                      <Grid
                        item
                        container
                        alignItems="center"
                        pb={theme.spacing(3)}
                      >
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            fontWeight={500}
                            className={classes.subItemTitle}
                          >
                            {subItem.feeType}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={6}
                          item
                          container
                          flexDirection="column"
                          textAlign="right"
                        >
                          <Grid>
                            <Typography
                              className={
                                subItem?.paidStatus === 'Successful'
                                  ? classes.subHeadingModeSuccess
                                  : classes.subHeadingModeFail
                              }
                            >
                              {subItem?.paidStatus}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography variant="h5" fontWeight={500}>
                              {subItem?.feeAmount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container alignItems="center">
                        <Grid xs={6} item container flexDirection="column">
                          <Grid
                            xs={6}
                            display="flex"
                            minWidth="100% !important"
                            alignItems="center"
                          >
                            <QueryBuilderIcon
                              style={{ height: '16px', color: '#092682' }}
                            />
                            <Typography
                              variant="h5"
                              fontWeight={500}
                              className={classes.date}
                            >
                              {subItem.date} {subItem?.time}
                            </Typography>
                          </Grid>
                          <Grid>{subItem?.transactionId}</Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h5"
                            fontWeight={500}
                            textAlign="right"
                          >
                            {subItem?.mode}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default function TransactionsHistory({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const headerCell = (value) => {
    return (
      <Typography variant="h5" fontWeight={500}>
        {value}
      </Typography>
    );
  };

  const convertBase64ToFile = (base64String, fileName) => {
    // const base64WithoutPrefix = base64String.substr(
    //   'data:application/pdf;base64,'.length
    // );
    // console.log(base64WithoutPrefix);

    const bytes = atob(base64String);
    let n = bytes.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bytes.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: 'application/pdf' });
    return file;
  };

  const handleDownloadReceipt = (transactionId) => {
    getFeeReceipt(transactionId).then((res) => {
      // var blob = new Blob([data.body], { type: 'application/pdf' });
      // var blobUrl = URL.createObjectURL(blob);
      // console.log(blobUrl);
      // var newBlob = new Blob([res?.data?.body], { type: 'application/pdf' });

      // // IE doesn't allow using a blob object directly as link href
      // // instead it is necessary to use msSaveOrOpenBlob
      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //   window.navigator.msSaveOrOpenBlob(newBlob);
      //   return;
      // }

      // // For other browsers:
      // // Create a link pointing to the ObjectURL containing the blob.
      // const data = window.URL.createObjectURL(newBlob);
      // console.log(data);
      // var link = document.createElement('a');
      // link.href = data;
      // link.download = res.data.title;
      // link.click();
      // setTimeout(function () {
      //   // For Firefox it is necessary to delay revoking the ObjectURL
      //   window.URL.revokeObjectURL(data);
      // }, 100);
      const file = convertBase64ToFile(res?.data?.body, res?.data?.title);
      saveAs(file, res?.data?.title);
    });
  };

  const columns = [
    {
      Header: headerCell('Fee Type'),
      accessor: 'feeType',
      Cell: ({ row, value }) => {
        return (
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <Typography variant="h5" fontWeight={500}>
                {value}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                fontWeight={500}
                className={classes.subHeadingFeeType}
              >
                {row?.original?.programName}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      Header: headerCell('Fee Amount'),
      accessor: 'feeAmount',
      Cell: ({ row, value }) => {
        return (
          <Typography variant="h5" fontWeight={500}>
            {value}
          </Typography>
        );
      },
    },
    {
      Header: headerCell('Transaction ID'),
      accessor: 'transactionId',
      Cell: ({ row, value }) => {
        return (
          <Typography variant="h5" fontWeight={500}>
            {value}
          </Typography>
        );
      },
    },
    {
      Header: headerCell('Date'),
      accessor: 'transactionDate',
      Cell: ({ row, value }) => {
        return (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              {value && (
                <Typography variant="h5" fontWeight={500}>
                  {moment(value).format('DD-MM-YYYY')}
                </Typography>
              )}
            </Grid>
            <Grid item>
              {value && (
                <Typography
                  variant="h5"
                  fontWeight={500}
                  className={classes.subHeadingFeeType}
                >
                  {moment(value).format('hh:mm')}
                </Typography>
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      Header: headerCell('Mode'),
      accessor: 'mode',
      Cell: ({ row, value }) => {
        return (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="h5" fontWeight={500}>
                {value}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={
                  row?.original?.status === 'Success'
                    ? classes.subHeadingModeSuccess
                    : classes.subHeadingModeFail
                }
              >
                {row?.original?.status}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      Header: headerCell('Payment Type'),
      accessor: 'paymentType',
      Cell: ({ row, value }) => {
        return (
          <Grid container direction="column" spacing={1} width={100}>
            <Grid item>
              <Typography
                className={
                  value === 'Partial'
                    ? row?.original?.status === 'Success'
                      ? classes.typoPaymentTypePartialSuccess
                      : classes.typoPaymentTypePartialFailed
                    : row?.original?.status === 'Failed'
                    ? classes.typoPaymentTypeFullFailed
                    : classes.typoPaymentTypeFullSuccess
                }
              >
                {value}
              </Typography>
            </Grid>
            <Grid item>
              {value && (
                <Typography className={classes.subHeadingPaymentTypeSuccess}>
                  {row?.original?.status === 'Success'
                    ? 'Completed'
                    : 'Incomplete'}
                </Typography>
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      Header: headerCell('Receipt'),
      accessor: 'recordId',
      Cell: ({ row, value }) => {
        return (
          <Grid container direction="column" spacing={1} width={100}>
            <Grid item>
              {value && (
                <Typography
                  variant="subtitle2"
                  className={classes.download}
                  onClick={() => {
                    handleDownloadReceipt(value);
                  }}
                >
                  Download Receipt
                </Typography>
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'expander',
      Cell: ({ row }) =>
        row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                width: 20,
              },
            })}
          >
            {row.isExpanded ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </span>
        ) : null,
    },
  ];

  return (
    <div className={classes.rootContainer}>
      {isMobileScreen ? (
        <RenderTransactionCards data={data} />
      ) : (
        <KenGrid
          toolbarDisabled={true}
          columns={columns}
          data={data}
          pagination={{ disabled: true }}
          initialState={{ hiddenColumns: ['status'] }}
          gridProps={{
            getHeaderProps: (column) => ({
              style: {
                textAlign: column.id === 'feeType' ? 'left' : 'center',
                background: '#F7F8FB',
                borderRadius: '8px 8px 0px 0px',
                color: '#061938',
                textTransform: 'none',
              },
            }),
          }}
        />
      )}
    </div>
  );
}
